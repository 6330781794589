export const resizeImage = async (
  imageBlob,
  targetSize = 1024,
  quality = 0.7
) => {
  const createImage = (src) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (e) => reject(e);
      img.src = src;
    });

  const image = await createImage(URL.createObjectURL(imageBlob));

  // Crop the image to a square
  const size = Math.min(image.width, image.height);
  const startX = image.width > size ? (image.width - size) / 2 : 0;
  const startY = image.height > size ? (image.height - size) / 2 : 0;

  const canvas = document.createElement("canvas");
  canvas.width = targetSize;
  canvas.height = targetSize;

  const ctx = canvas.getContext("2d");
  ctx.drawImage(
    image,
    startX,
    startY,
    size,
    size,
    0,
    0,
    targetSize,
    targetSize
  );

  return new Promise((resolve) => {
    canvas.toBlob(resolve, "image/jpeg", quality);
  });
};
