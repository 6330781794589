import discordIcon from "@icons/discord.svg";

import styles from "./Help.module.css";
import { HELP_LINKS } from "./constants";

const Help = () => {
  return (
    <div className={styles.helpContent}>
      {HELP_LINKS.map((link) => (
        <a
          key={link.id}
          href={link.url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {link.label}
        </a>
      ))}
      <div className={styles.socials}>
        <a
          href="https://discord.com/invite/fG9bEbjPCx"
          rel="noopener noreferrer"
          target="_blank"
        >
          Join our community
        </a>
        <img src={discordIcon} alt="Discord icon" />
      </div>
    </div>
  );
};

export default Help;
