import { Contract } from "ethers";
import { useMemo } from "react";

import { useWalletState } from "@Web3/context";

import DecentralverseCollection from "../abi/DecentralverseCollection.json";

const useDecentralverseCollectionContract = ({ contractAddress }) => {
  const { signer } = useWalletState();

  const decentralverseCollectionContract = useMemo(() => {
    if (!contractAddress || !signer) {
      return null;
    }
    return new Contract(contractAddress, DecentralverseCollection, signer);
  }, [contractAddress, signer]);

  return decentralverseCollectionContract;
};

export default useDecentralverseCollectionContract;
