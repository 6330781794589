import config from "@config/index";
import { Contract } from "ethers";
import { useMemo } from "react";

import { useWalletState } from "@Web3/context";

import Erc20 from "../abi/Erc20.json";

const useErc20Contract = () => {
  const { signer } = useWalletState();

  const erc20Contract = useMemo(() => {
    const contractAddress = config.contracts.erc20Address;
    if (!contractAddress || !signer) {
      return null;
    }
    return new Contract(contractAddress, Erc20, signer);
  }, [signer]);

  return erc20Contract;
};

export default useErc20Contract;
