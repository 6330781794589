// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rqTY6pSn {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.DdGmIsDh {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aeBg0DKS {
  border: 16px solid #ec1987;
  border-top: 16px solid #34495e;
  border-radius: 50%;
  animation: Wy9FdvTE 2s linear infinite;
}

@keyframes Wy9FdvTE {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.E9m2DBHO {
  color: #ec1987;
  font-size: 24px;
  margin-top: 24px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": `rqTY6pSn`,
	"centeredContent": `DdGmIsDh`,
	"loader": `aeBg0DKS`,
	"spin": `Wy9FdvTE`,
	"loaderMessage": `E9m2DBHO`
};
export default ___CSS_LOADER_EXPORT___;
