import PropTypes from "prop-types";

import { clsx } from "@shared/helpers";

import styles from "./Input.module.css";

const Input = ({
  id,
  name,
  label,
  required = false,
  type = "text",
  placeholder,
  className,
  register,
  error,
  ...rest
}) => {
  return (
    <div className={clsx(styles.container, className)}>
      <fieldset className={styles.fieldset}>
        <legend
          className={clsx(styles.fieldsetLegend, required && styles.required)}
        >
          {label}
        </legend>
        <input
          id={id}
          type={type}
          name={name}
          className={styles.fieldsetInput}
          placeholder={placeholder || ""}
          {...rest}
          {...register}
        />
      </fieldset>
      {error && (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      )}
    </div>
  );
};

Input.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.object.isRequired,
  error: PropTypes.string,
  required: PropTypes.bool,
};

export default Input;
