const assetRoutes = {
  getAssetTypes: () => `/api/assets/asset-types`,
  getAssetTypeShares: () => `/api/assets/asset-type-shares`,
  getAssets: () => `/api/assets`,
  getAsset: (identifier) => `/api/assets/${identifier}`,
  createAsset: () => "/api/assets",
  updateAsset: (identifier) => `/api/assets/${identifier}`,
  updateAssetPosition: (identifier) => `/api/assets/${identifier}/position`,
  deleteAsset: (identifier) => `/api/assets/${identifier}`,
};

export { assetRoutes };
