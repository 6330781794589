// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZAVhwMrY {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mEhYgFFM {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.eWMKw3sU {
  z-index: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.YkEE9Aag {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 40px;
}

.aqZSt8RM {
  max-width: 107px;
  max-height: 107px;
  width: 100%;
  margin-left: -0.5rem;
}
.UDDR5fhu {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.e0B7tbCE {
  max-height: 46px;
  width: 100%;
  height: 100%;
}
.sw7rywCA {
  color: #ffffff;
  font-family: "Lato", sans-serif;
  font-size: 56px;
  line-height: 36px;
  font-weight: 800;
  white-space: nowrap;
}

.ihaj91T7 {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 1rem 0 2rem;
  position: relative;
  left: 6.5rem;
}

.SIRwb_Wt {
  font-family: "Lato", sans-serif;
  font-size: 26px;
  font-style: normal;
  font-weight: 900;
  line-height: 36px;
  width: 100%;
  text-align: center;
  margin-left: 1.2rem;
  color: #ffffff;
}

.h8xYe9fb {
  position: relative;
}

.bL8YChik {
  width: 310px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}

._mTJ2TtU {
  padding: 16px 34px;
  height: 2.6rem;
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #010519;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  cursor: pointer;
  white-space: nowrap;
  border: none !important;
  background: #ff0691 !important;
  background: linear-gradient(90deg, #ff0691 7.11%, #fe1650 100%) !important;
  border-radius: 8px;
  width: 155px;
}

.adcONoPA {
  bottom: 36px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #fff;
  text-align: center;
  margin: 2rem 0;
}

.eoFpcYGo,
.eoFpcYGo > a {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: red;
  line-height: 12px;
}

.eoFpcYGo {
  position: absolute;
  top: 100%;
  width: 100%;
  display: inline-block;
  padding: 0.25rem 0.5rem 0.5rem;
  text-align: center;
  margin-top: 1rem;
}

.eoFpcYGo > a {
  text-decoration: underline;
}

@media screen and (max-width: 768px) {
  .sw7rywCA {
    font-size: 38px;
    line-height: 24px;
  }
  .ihaj91T7 {
    flex-direction: column;
    gap: 38px;
    left: 0;
  }
  .UDDR5fhu {
    gap: 0.5rem;
  }
  .aqZSt8RM {
    max-width: 78px;
    height: auto;
  }
  .e0B7tbCE {
    max-height: 34px;
    height: 100%;
  }

  .eoFpcYGo {
    margin-top: 0.5rem;
  }

  .SIRwb_Wt {
    margin: 0;
  }

  .NXr2T_nx {
    margin-bottom: 1rem;
  }

  .bL8YChik {
    margin-bottom: 0;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreen": `ZAVhwMrY`,
	"video": `mEhYgFFM`,
	"content": `eWMKw3sU`,
	"header": `YkEE9Aag`,
	"logo": `aqZSt8RM`,
	"logoContainer": `UDDR5fhu`,
	"signatureLogo": `e0B7tbCE`,
	"title": `sw7rywCA`,
	"form": `ihaj91T7`,
	"inviteOnly": `SIRwb_Wt`,
	"inputWrapper": `h8xYe9fb`,
	"input": `bL8YChik`,
	"button": `_mTJ2TtU`,
	"copyright": `adcONoPA`,
	"whitelistError": `eoFpcYGo`,
	"countdown": `NXr2T_nx`
};
export default ___CSS_LOADER_EXPORT___;
