import PropTypes from "prop-types";
import { useState } from "react";

import { useCesiumApp } from "@features/Cesium/context/CesiumProvider";
import { flyToAsset, flyToLand } from "@features/Cesium/utils";

import Import3dAssetModal from "@components/Import3dAssetModal";
import CreateLandModal from "@components/ImportLandModal";
import Portal from "@components/Portal";

import { clsx } from "@shared/helpers";

import landSquare from "@icons/land-square.svg";
import land from "@icons/land.svg";
import landPlaceholder from "@icons/no-land.svg";
import openIcon from "@icons/open-arrow.svg";

import styles from "./Map.module.css";

const Map = ({
  landName,
  landImage,
  landVertices,
  landOwnerId,
  asset,
  userId,
}) => {
  const { viewer, setInteractionTooltipText } = useCesiumApp();
  const [openLandModal, setOpenLandModal] = useState(false);
  const [openUploadAssetModal, setOpenUploadAssetModal] = useState(false);

  const isLandOwner = userId === landOwnerId;
  const isAssetOwner = userId === asset?.ownerId;

  const onComplete = () => {
    setInteractionTooltipText("");
  };

  return (
    <div className={styles.landBlock}>
      <button
        className={clsx(
          styles.assetSquare,
          asset?.imageUrl && !isAssetOwner && styles.imageOverlay
        )}
        style={{
          cursor: asset?.imageUrl && !isAssetOwner ? "auto" : "pointer",
        }}
        onClick={() => {
          if (asset?.imageUrl && isAssetOwner) {
            setInteractionTooltipText(
              "Use Esc keyword to stop animation interaction"
            );
            flyToAsset(viewer, asset?.id, onComplete);
          } else if (!asset?.imageUrl && !landName) {
            setOpenUploadAssetModal(true);
          }
        }}
      >
        <img
          width={asset?.imageUrl ? "100%" : 36}
          height={asset?.imageUrl ? "100%" : 36}
          src={asset?.imageUrl || landSquare}
          alt="asset image"
        />
      </button>
      <div
        className={clsx(
          styles.landBlockImage,
          !isLandOwner && styles.imageOverlay
        )}
        style={{
          "--land-image": `url(${landImage || landPlaceholder})`,
          cursor: landImage && !isLandOwner ? "auto" : "pointer",
          backgroundSize: landImage ? "cover" : "auto",
        }}
        onClick={() => {
          if (isLandOwner && landImage) {
            setInteractionTooltipText(
              "Use Esc keyword to stop animation interaction"
            );
            flyToLand(viewer, landVertices, onComplete);
          } else if (!landName) {
            setOpenLandModal(true);
          }
        }}
      />
      <div className={styles.landBlockSmallImage} />
      {landName && (
        <div className={styles.landBlockInfoWrapper}>
          {landName && isLandOwner && (
            <div
              className={clsx(
                styles.landBlockInfo,
                !isLandOwner && styles.textOverlay
              )}
              onClick={() => {
                setInteractionTooltipText(
                  "Use Esc keyword to stop animation interaction"
                );
                flyToLand(viewer, landVertices, onComplete);
              }}
            >
              <img src={land} />
              <span>{landName}</span>
              <img src={openIcon} alt="Open icon" />
            </div>
          )}
          {asset?.imageUrl && isAssetOwner && (
            <div
              className={clsx(
                styles.landBlockInfo,
                !isAssetOwner && styles.textOverlay
              )}
              onClick={() => {
                setInteractionTooltipText(
                  "Use Esc keyword to stop animation interaction"
                );
                flyToAsset(viewer, asset?.id, onComplete);
              }}
            >
              <img src={landSquare} width={13} height={13} />
              <span>{asset?.name || ""}</span>
              <img src={openIcon} alt="Open icon" />
            </div>
          )}
        </div>
      )}
      {openUploadAssetModal && (
        <Portal>
          <Import3dAssetModal onClose={() => setOpenUploadAssetModal(false)} />
        </Portal>
      )}
      {openLandModal && (
        <Portal>
          <CreateLandModal onClose={() => setOpenLandModal(false)} />
        </Portal>
      )}
    </div>
  );
};

Map.propTypes = {
  landName: PropTypes.string,
  landImage: PropTypes.string,
  landVertices: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
  landOwnerId: PropTypes.string,
  asset: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    imageUrl: PropTypes.string,
    ownerId: PropTypes.string,
  }),
  userId: PropTypes.string,
};

export default Map;
