import axios from "axios";

import storage from "@services/storage";

import routes from "./routes";

function removeEmptyFields(data) {
  const filteredData = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== "") {
      filteredData[key] = data[key];
    }
  });
  return filteredData;
}

const fetchData = async ({
  url,
  routeKey,
  method = "GET",
  data = null,
  authenticate = false,
  customHeaders = {},
  params = {},
  isFormData = false,
}) => {
  const urlPath = routeKey ? routes[routeKey](params) : url;

  if (!urlPath) {
    throw new Error(`Route not found for key: ${routeKey}`);
  }

  const headers = {
    ...customHeaders,
  };

  if (isFormData) {
    headers["Content-Type"] = "multipart/form-data";
  } else {
    headers["Content-Type"] = "application/json";
  }

  if (authenticate) {
    const token = storage.get("token");
    headers.Authorization = `Bearer ${token}`;
  }

  const instance = axios.create({ headers });

  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = storage.get("refreshToken");
        if (!refreshToken) {
          throw error;
        }

        // eslint-disable-next-line no-useless-catch
        try {
          const { data } = await axios.post("/api/refreshToken", {
            refreshToken,
          });
          storage.set("token", data.token);
          originalRequest.headers.Authorization = `Bearer ${data.token}`;
          return instance(originalRequest);
        } catch (refreshError) {
          throw refreshError;
        }
      }
      throw error?.response?.data;
    }
  );

  const requestData = {
    method,
    url: urlPath,
  };

  if (data) {
    requestData.data = isFormData ? data : removeEmptyFields(data);
  }

  const { data: responseData } = await instance(requestData);
  return responseData;
};

export default fetchData;
