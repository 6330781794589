const listingRoutes = {
  getFloorPriceForNfts: (nftType) => `/api/listing/${nftType}/floor-price`,
  listNftForSale: (nftId) => `/api/listing/nft/${nftId}`,
  cancelNftListing: (nftId) => `/api/listing/nft/${nftId}`,
  makeOfferForNft: (nftId) => `/api/offer/nft/${nftId}`,
  getHighestOffer: (nftId) => `/api/offer/nft/${nftId}/highest`,
  declineNftOffer: (nftId) => `/api/offer/nft/decline/${nftId}`,
};

export { listingRoutes };
