import { useMutationData, useQueryData } from "@api/hooks";
import { userService } from "@api/services";

import { useWalletState } from "@Web3/context";

const useUserQuery = (config) => {
  const { currentAddress } = useWalletState();
  const { data, ...restOfQueryParams } = useQueryData(
    ["users", currentAddress],
    async () => await userService.getUser(currentAddress),
    { enabled: !!currentAddress, ...config }
  );

  const { mutateAsync, isLoading, error } = useMutationData(
    userService.updateUser,
    {
      invalidateQueries: [["users", currentAddress]],
    }
  );

  return {
    user: data,
    updateUser: mutateAsync,
    isUpdatingUser: isLoading,
    updateUserError: error,
    ...restOfQueryParams,
  };
};

export { useUserQuery };
