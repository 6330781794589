export function findPrimitiveById(primitivesCollection, primitiveId) {
  if (!primitivesCollection || !primitiveId) {
    return;
  }
  for (let i = 0; i < primitivesCollection.length; i++) {
    const primitive = primitivesCollection.get(i);
    if (primitive.id === primitiveId) {
      return primitive;
    }
  }
  return null;
}
