import config from "@config/index";
import { useQuery } from "@tanstack/react-query";

import { Erc20ContractMethods } from "@Web3/constants";
import { useWalletState } from "@Web3/context";

import useErc20Contract from "@contracts/hooks/useErc20Contract";

const useAllowance = () => {
  const { currentAddress } = useWalletState();
  const erc20Contract = useErc20Contract();

  return useQuery(
    [Erc20ContractMethods.ALLOWANCE],
    () =>
      erc20Contract.allowance(
        currentAddress,
        config.contracts.dcvExchangeAddress
      ),
    {
      enabled: !!currentAddress && !!erc20Contract,
    }
  );
};

export default useAllowance;
