// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.eE81hBbN > div {
  max-width: 600px;
}

.LZ8g5JFq {
  display: flex;
  flex-direction: row;
}

.vkdpkrqo {
  max-width: 78%;
  width: 100%;
  padding: 0 25px;
}

.vkdpkrqo fieldset:nth-of-type(4) {
  margin-bottom: 0;
}

.mzK_SKUy {
  position: relative;
  display: flex;
  flex-direction: row;
}

.aszhhScQ {
  margin: 0;
  line-height: 18.6px;
}

._VlvZJzu {
  position: absolute;
  right: 0;
  top: 45%;
  z-index: 2;
  cursor: pointer;
}

.aszhhScQ {
  margin: 0;
  line-height: 18.6px;
}

._VlvZJzu {
  position: absolute;
  right: 0.5rem;
}

.Jp18CMJc {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  height: 60px;
}

.ItUhkDw9 {
  position: relative;
  outline: none;
  background: none;
  border: none;
  gap: 10px;
  width: 106px;
  height: 36px;
  background: linear-gradient(90deg, #ff0691 7.11%, #fe1650 100%);
  border-radius: 8px;
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ItUhkDw9:hover {
  cursor: pointer;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accountModal": `eE81hBbN`,
	"accountBody": `LZ8g5JFq`,
	"containerBodyRightForm": `vkdpkrqo`,
	"accountWalletAddressRoot": `mzK_SKUy`,
	"accountWalletAddress": `aszhhScQ`,
	"accountWalletAddressCopyIcon": `_VlvZJzu`,
	"accountFooter": `Jp18CMJc`,
	"accountFooterButton": `ItUhkDw9`
};
export default ___CSS_LOADER_EXPORT___;
