import fetchData from "@api/axios";

const nftService = {
  async getNftById(nftId) {
    return await fetchData({
      routeKey: "getNftById",
      params: nftId,
    });
  },
  async getOwnedNfts(userId) {
    return await fetchData({
      routeKey: "getOwnedNfts",
      params: userId,
    });
  },
  async createAssetNft(data) {
    return await fetchData({
      routeKey: "createAssetNft",
      method: "POST",
      data,
    });
  },
};

export { nftService };
