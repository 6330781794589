import fetchData from "@api/axios";

const tradeService = {
  async getTradingHistoryByNftId(nftId) {
    return await fetchData({
      routeKey: "getTradingHistoryByNftId",
      params: nftId,
    });
  },
  async tradeNft({ nftId, data }) {
    return await fetchData({
      routeKey: "tradeNft",
      method: "POST",
      params: nftId,
      data,
    });
  },
  async acceptNftOffer({ nftId, data }) {
    return await fetchData({
      routeKey: "acceptNftOffer",
      method: "POST",
      params: nftId,
      data,
    });
  },
};

export { tradeService };
