import { ScreenSpaceEventHandler, ScreenSpaceEventType } from "cesium";
import { useEffect, useRef } from "react";

import { useCesiumApp } from "@features/Cesium/context/CesiumProvider";

const useOutsideClick = ({ callback, enabled }) => {
  const ref = useRef();
  const handlerRef = useRef(null);

  const { viewer } = useCesiumApp();

  useEffect(() => {
    if (!enabled) {
      return;
    }

    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClick);

    if (viewer?.canvas) {
      handlerRef.current = new ScreenSpaceEventHandler(viewer.canvas);
      handlerRef.current.setInputAction((event) => {
        handleClick(event);
      }, ScreenSpaceEventType.LEFT_DOWN);
    }

    return () => {
      document.removeEventListener("mousedown", handleClick);
      if (handlerRef.current) {
        handlerRef.current.removeInputAction(ScreenSpaceEventType.LEFT_DOWN);
        handlerRef.current = null;
      }
    };
  }, [callback, enabled, viewer?.canvas]);

  return ref;
};

export { useOutsideClick };
