import {
  BoundingSphere,
  Cartographic,
  HeadingPitchRange, // eslint-disable-next-line no-unused-vars
  Viewer,
  Cartesian3 as cesiumCartesian3,
  sampleTerrain,
} from "cesium";

/**
 * Generates a function comment for the given function body.
 *
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {Array} polygonCoordinates - The array of polygon coordinates.
 * @param {Function} [onComplete] - The callback function to be executed when the flight is complete.
 * @return {Promise} A promise that resolves when the function completes.
 */
export const flyToLand = async (
  viewer,
  polygonCoordinates,
  onComplete = () => {}
) => {
  const cartographicPoints = polygonCoordinates?.map(([longitude, latitude]) =>
    Cartographic.fromDegrees(latitude, longitude)
  );

  const terrainProvider = viewer?.terrainProvider;

  if (!terrainProvider) {
    return;
  }

  const raisedPositions = await sampleTerrain(
    terrainProvider,
    11,
    cartographicPoints
  );

  const points = raisedPositions.map(({ longitude, latitude, height }) =>
    cesiumCartesian3.fromRadians(longitude, latitude, height)
  );

  const boundingSphere = BoundingSphere.fromPoints(points);
  const adjustedBoundingSphere = new BoundingSphere(
    boundingSphere.center,
    boundingSphere.radius
  );
  const offset = new HeadingPitchRange(0, -Math.PI / 2);

  const handleEscapePress = (event) => {
    if (event.key === "Escape") {
      viewer.camera.cancelFlight();
      document.removeEventListener("keydown", handleEscapePress);
    }
  };

  const handleMoveEnd = () => {
    viewer.camera.moveEnd.removeEventListener(handleMoveEnd);
    document.removeEventListener("keydown", handleEscapePress);
    onComplete();
  };

  document.addEventListener("keydown", handleEscapePress);
  viewer.camera.moveEnd.addEventListener(handleMoveEnd);

  viewer.camera.flyToBoundingSphere(adjustedBoundingSphere, {
    duration: 6,
    offset,
    complete: () => {
      onComplete();
    },
  });
};
