import { useEffect, useRef } from "react";

export function useMouseHold(callback = () => {}, initialDelay = 200) {
  const callbackRef = useRef(callback);
  const loopRef = useRef();
  const timeoutRef = useRef();

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    return () => {
      if (loopRef.current) {
        cancelAnimationFrame(loopRef.current);
      }
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const start = () => {
    if (loopRef.current) {
      cancelAnimationFrame(loopRef.current);
    }
    callbackRef.current();
    timeoutRef.current = setTimeout(() => {
      loopRef.current = requestAnimationFrame(loop);
    }, initialDelay);
  };

  const loop = () => {
    callbackRef.current();
    loopRef.current = requestAnimationFrame(loop);
  };

  const end = () => {
    if (loopRef.current) {
      cancelAnimationFrame(loopRef.current);
    }
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return {
    onMouseDown: start,
    onTouchStart: start,
    onMouseUp: end,
    onMouseLeave: end,
    onTouchEnd: end,
  };
}
