/**
 * Formats a number with comma separators and configurable decimal places.
 *
 * @param {number|string} number - The number to format.
 * @param {number} [minDecimals=2] - The minimum number of decimal places.
 * @param {number} [maxDecimals=4] - The maximum number of decimal places.
 * @return {string} The formatted number with commas and specified decimal places.
 */
export function formatCurrency(number, minDecimals = 2, maxDecimals = 4) {
  if (number === undefined || number === null || isNaN(number)) {
    return "0.00";
  }

  const numericValue = typeof number === "string" ? parseFloat(number) : number;

  if (numericValue === 0) {
    return `0.${"0".repeat(minDecimals)}`;
  }

  return numericValue.toLocaleString(navigator.language, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
    useGrouping: true,
  });
}

export const defaultFormatter = {
  format: (value) => value,
  parse: (value) => value,
};

export const currencyFormatter = ({ locale, currency = "" } = {}) => {
  return {
    format: (value) => {
      if (value === undefined || value === null || value === "") {
        return ""; // Return an empty string if the value is empty or undefined
      }

      let cleanedValue = value.replace(/[^0-9.]/g, "");

      // Allow only one decimal point
      const parts = cleanedValue.split(".");
      cleanedValue =
        parts.length > 2
          ? `${parts[0]}.${parts.slice(1).join("")}`
          : cleanedValue;

      // Remove leading zeros unless the number starts with "0."
      if (!cleanedValue.startsWith("0.") && cleanedValue !== "0") {
        cleanedValue = cleanedValue.replace(/^0+(?=\d)/, "");
      }

      // Format the integer part with commas
      const [integerPart, decimalPart] = cleanedValue.split(".");
      let formattedValue = Number(integerPart).toLocaleString(
        locale || navigator.language
      );

      // If there was a decimal point, add it back with the decimal part (even if empty)
      if (parts.length > 1) {
        formattedValue += `.${decimalPart !== undefined ? decimalPart : ""}`;
      }

      return `${formattedValue || ""} ${currency}`.trim();
    },
    parse: (value) => {
      // Strip out non-numeric characters (except for decimal points)
      const rawValue = value.replace(/[^0-9.-]/g, "");
      return rawValue ? parseFloat(rawValue) : ""; // Parse the input or return an empty string
    },
    cleanValue: (value) => (!value ? "" : value.replace(/,/g, "")),
  };
};
