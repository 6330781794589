import { useQueryData } from "@api/hooks";

import { getMaticToUsdRate } from "@Web3/utils";

const useMaticToUsdRate = () => {
  return useQueryData(["usd-price"], getMaticToUsdRate, {
    refetchInterval: 20000,
  });
};

export default useMaticToUsdRate;
