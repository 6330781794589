/**
 * Trims a number to a maximum of 18 decimal places without rounding and removes trailing zeros.
 *
 * @param {number|string} value - The number to format.
 * @return {string} The formatted number trimmed to 18 decimal places.
 */
export function formatPriceValue(value) {
  if (value === undefined || value === null || isNaN(value)) {
    return "0";
  }

  // Convert to string if it's a number
  const stringValue = typeof value === "number" ? value.toString() : value;

  // Find the position of the decimal point
  const decimalIndex = stringValue.indexOf(".");

  if (decimalIndex === -1) {
    // No decimal point, return the original value
    return stringValue;
  }

  // Cut the string after 18 decimal places
  const trimmedValue = stringValue.substring(0, decimalIndex + 19);

  // Remove trailing zeros after the decimal point
  const result = trimmedValue.replace(/\.?0+$/, "");

  return result;
}
