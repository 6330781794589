import config from "@config/index";
import { JsonRpcProvider } from "ethers";

export const initialWalletActions = {
  connectToEthereum: () => null,
  disconnect: () => null,
  addChain: () => null,
  switchChain: () => null,
  toggleWalletConnectModal: () => null,
};

export const initialState = {
  web3: null,
  ethereumProvider: null,
  currentAddress: null,
  chainId: null,
  signer: null,
  isCorrectChain: false,
  isConnecting: false,
  isConnectWalletModalOpen: false,
  web3WalletEvent: null,
  readOnlyProvider: new JsonRpcProvider(config.blockchain.rpcUrl),
};
