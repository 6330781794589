import PropTypes from "prop-types";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useMutationData } from "@api/hooks";
import { authService, fileService, userService } from "@api/services";

import { UploadImage } from "@features/Upload";
import VerificationModal from "@features/VerificationModal";

import CheckBox from "@components/Form/CheckBox/CheckBox";
import Input from "@components/Form/Input/Input";
import TextArea from "@components/Form/Input/TextArea";
import { Loader } from "@components/Loader";
import Modal from "@components/Modal";

import { useClipboard } from "@shared/hooks";
import { resizeImage } from "@shared/utils";

import copy from "@icons/copy.svg";
import uploadAvatar from "@icons/upload-avatar.png";

import styles from "./Profile.module.css";

const Profile = ({ address, user, onProfileSubmit, onClose }) => {
  const formRef = useRef(null);
  const [prevEmailAddress, setPrevEmailAddress] = useState("");

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    getValues,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: user?.id,
      username: user?.username || "",
      email: user?.email || "",
      bio: user?.bio || "",
      walletAddress: address || "",
      emailNotifications: user ? user.emailNotifications : false,
      profileImage: null,
      verificationCode: null,
    },
    reValidateMode: "onChange",
  });

  const { copyToClipboard } = useClipboard();

  const { mutateAsync } = useMutationData(userService.updateUser, {
    invalidateQueries: [
      ["users", address],
      ["trade-history"],
      ["polygons"],
      ["assets"],
    ],
  });

  const watchedFile = watch("profileImage");

  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async (formData) => {
    const { id, username, email } = formData;

    try {
      const checkUsername = await authService.checkUsername(id, username);
      const checkEmail = await authService.checkEmail(id, email);
      if (!checkUsername.valid) {
        setError("username", {
          type: "server",
          message: checkUsername.message || "Username is already in use",
        });
        return;
      }
      if (!checkEmail.valid) {
        setError("email", {
          type: "server",
          message: checkEmail.message || "Email is already in use",
        });
        return;
      }
    } catch (error) {
      setError(error.field, {
        type: "server",
        message: error?.message || "Something went wrong, please try later",
      });
      return;
    }

    setIsSubmitting(true);
    try {
      if (email && prevEmailAddress !== email && !user?.isVerified) {
        await authService.sendVerificationCode(email);

        setShowVerificationModal(true);
      } else {
        await handleSaveUserData(formData);
      }
    } catch (error) {
      console.error("Error sending the verification code:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();
    const { verificationCode, ...rest } = getValues();

    if (!verificationCode) {
      setError("verificationCode", {
        type: "custom",
        message: "Verification code is required",
      });
      return;
    }
    if (!/^\d{6}$/.test(verificationCode)) {
      setError("verificationCode", {
        type: "custom",
        message: "Enter a valid 6 digit code",
      });
      return;
    }
    setIsSubmitting(true);
    try {
      const response = await authService.verifyCode(
        watch("email"),
        watch("verificationCode")
      );

      if (response.valid) {
        await handleSaveUserData({ ...rest });
      } else {
        setError("verificationCode", {
          type: "server",
          message: "Verification code is invalid",
        });
      }
    } catch (error) {
      setError("verificationCode", {
        type: "server",
        message: "Verification code is invalid or expired",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSaveUserData = async (formData) => {
    // eslint-disable-next-line no-unused-vars
    const { id, verificationCode, ...rest } = formData;
    let imageUrl = null;

    if (watchedFile) {
      try {
        const profileImageResized = await resizeImage(watchedFile, 512);
        imageUrl = await fileService.uploadFile({
          file: profileImageResized,
          category: "user-profile",
        });
      } catch (error) {
        console.error("Error uploading the image:", error);
        toast.error("Something went wrong with uploading image!");
        return;
      }
    }

    const data = {
      ...rest,
      imageUrl: imageUrl || user?.imageUrl,
      isVerified: !!rest.email,
    };

    try {
      if (id) {
        await mutateAsync({
          ...data,
        });
        toast.success("You have successfully updated your account");
      }
      onProfileSubmit && onProfileSubmit();
    } catch (error) {
      toast.error("Something went wrong while updating your account");
    }
  };

  const handleCloseVerification = () => {
    setIsSubmitting(false);
    setShowVerificationModal(false);
  };

  return (
    <>
      <Modal
        className={styles.accountModal}
        title={
          user?.id
            ? "Update your Decentralverse account"
            : "Create your Decentralverse account"
        }
        size="large"
        onClose={onClose}
      >
        <form
          ref={formRef}
          onSubmit={handleSubmit(onSubmit)}
          className={styles.accountBody}
        >
          <UploadImage
            id="profileImage"
            name="profileImage"
            accept=".jpg, .png, .gif, .JPG, .PNG, .GIF, .webp, .WEBP"
            register={register("profileImage")}
            onFileChange={(event) => {
              setValue("profileImage", event.target.files[0]);
            }}
            data={user?.imageUrl}
            defaultAvatar={uploadAvatar}
          />

          <div className={styles.containerBodyRightForm}>
            <Input
              id="username"
              name="username"
              label="Username"
              type="text"
              placeholder="Give yourself a name"
              register={register("username", {
                minLength: {
                  value: 3,
                  message: "Username must be at least 3 characters",
                },
                maxLength: {
                  value: 30,
                  message: "Username must be no more than 30 characters",
                },
                pattern: {
                  value: /^[a-zA-Z][a-zA-Z0-9]*$/,
                  message:
                    "Username should start with a letter and contain only letters and numbers, without spaces or special characters",
                },
              })}
              error={errors.username?.message}
            />
            <Input
              id="email"
              name="email"
              label="Email"
              type="email"
              placeholder="What's your email?"
              register={register("email", {
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Invalid email address",
                },
              })}
              disabled={!!user?.email}
              error={errors.email?.message}
            />

            <TextArea
              id="bio"
              name="bio"
              label="Bio"
              placeholder="Tell the world your story!"
              maxLength="320"
              register={register("bio")}
            />

            <CheckBox
              id="emailNotifications"
              name="emailNotifications"
              label="Send me Email Notifications"
              register={register("emailNotifications")}
            />
            <div className={styles.accountWalletAddressRoot}>
              <Input
                id="walletAddress"
                name="walletAddress"
                label="Wallet Address"
                type="text"
                disabled
                register={register("walletAddress")}
              />
              <img
                onClick={() => copyToClipboard(address || "")}
                src={copy}
                className={styles.accountWalletAddressCopyIcon}
              />
            </div>
          </div>
        </form>
        <div className={styles.accountFooter}>
          <button
            disabled={Object.keys(errors).length !== 0 || isSubmitting}
            onClick={() => formRef.current && formRef.current.requestSubmit()}
            className={styles.accountFooterButton}
          >
            {isSubmitting ? <Loader size="xs" /> : "Save"}
          </button>
        </div>
      </Modal>
      {showVerificationModal && (
        <VerificationModal
          email={watch("email")}
          onClose={handleCloseVerification}
          handleVerificationSubmit={handleVerificationSubmit}
          handleUpdateEmailAddress={() => {
            setShowVerificationModal(false);
            setPrevEmailAddress(watch("email"));
          }}
          register={register}
          errors={errors}
        />
      )}
    </>
  );
};

Profile.propTypes = {
  address: PropTypes.string,
  user: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    bio: PropTypes.string,
    email: PropTypes.string,
    imageType: PropTypes.string,
    walletAddress: PropTypes.string,
    imageUrl: PropTypes.string,
    emailNotifications: PropTypes.bool,
    isVerified: PropTypes.bool,
  }),
  onProfileSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default Profile;
