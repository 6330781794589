/* eslint-disable react/self-closing-comp */
import PropTypes from "prop-types";

import { clsx } from "@shared/helpers";

import styles from "./Settings.module.css";

const SettingsSwitch = ({ labelText, twoOptions = false, onChange, value }) => (
  <div className={clsx(styles.settingWrapper, twoOptions && styles.twoOptions)}>
    <p>{labelText}</p>
    <label className={styles.formSwitch} onChange={onChange}>
      <input type="checkbox" checked={value} onChange={onChange} />
      <i></i>
      {twoOptions && (
        <>
          <span className={styles.leftOption}>meters</span>
          <span className={styles.rightOption}>feet</span>
        </>
      )}
    </label>
  </div>
);

SettingsSwitch.propTypes = {
  labelText: PropTypes.string.isRequired,
  twoOptions: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool,
};

export default SettingsSwitch;
