import storage from "@services/storage";

import { connectors } from ".";
import { CACHED_CONNECTOR_ID_KEY } from "../constants";

const clearCachedConnector = () => {
  storage.remove(CACHED_CONNECTOR_ID_KEY);
};

const cacheConnector = (connector) => {
  storage.save(CACHED_CONNECTOR_ID_KEY, { id: connector.id });
};

const getCachedConnector = () => {
  const cachedConnector = storage.get(CACHED_CONNECTOR_ID_KEY);
  if (cachedConnector) {
    return connectors.find((c) => c.id === cachedConnector.id);
  }
  return null;
};

export { clearCachedConnector, cacheConnector, getCachedConnector };
