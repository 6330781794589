import "cesium/Build/Cesium/Widgets/widgets.css";
import { useCallback, useEffect, useState } from "react";

import { useAssetsQuery } from "@api/asset/hooks";
import { usePolygonsQuery } from "@api/polygon/hooks";
import { queryClient } from "@api/queryClient";

import { useAssets } from "@features/Asset/hooks";

import SwitchPlanet from "@components/SwitchPlanet";

import useWebSocket from "@shared/hooks/useWebSocket";

import { useCesiumApp } from "../context/CesiumProvider";
import { useCameraPosition, usePolygons } from "../hooks";
import { showPolygonAtCoordinates } from "../utils/showLandAtCoordinates";
import styles from "./ViewerContainer.module.css";

const ViewerContainer = () => {
  const { viewer, showEntities, cesiumContainerRef } = useCesiumApp();

  const [lookAtUserLocation, setLookAtUserLocation] = useState(false);

  const { toggleAssetsVisibility, loadDatabaseAssetsInViewer } = useAssets(
    viewer,
    showEntities.assets
  );
  const { togglePolygonsVisibility, loadDatabasePolygonsInViewer } =
    usePolygons(viewer);

  const { polygons } = usePolygonsQuery();
  const { assets } = useAssetsQuery();

  useEffect(() => {
    loadDatabasePolygonsInViewer(polygons);
    loadDatabaseAssetsInViewer(assets, polygons);
  }, [
    assets,
    loadDatabaseAssetsInViewer,
    loadDatabasePolygonsInViewer,
    polygons,
  ]);

  useEffect(() => {
    togglePolygonsVisibility(showEntities.polygons);
    toggleAssetsVisibility(showEntities.assets);
  }, [showEntities, toggleAssetsVisibility, togglePolygonsVisibility]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const lat = parseFloat(urlParams.get("lat") || urlParams.get("LAT"));
    const lon = parseFloat(urlParams.get("lon") || urlParams.get("LON"));

    if (!isNaN(lat) && !isNaN(lon)) {
      const polygonFound = showPolygonAtCoordinates(viewer, polygons, lat, lon);
      if (!polygonFound) {
        setLookAtUserLocation(true);
      }
    } else {
      setLookAtUserLocation(true);
    }
  }, [viewer, polygons]);

  useCameraPosition(viewer, lookAtUserLocation);

  const handleNftUpdate = useCallback((data) => {
    queryClient.invalidateQueries([`${data?.nftType}s`, data?.nftTypeId]);
    queryClient.invalidateQueries(["nfts", data?.nftId]);
    queryClient.invalidateQueries(["owned-nfts"]);
    queryClient.invalidateQueries(["trade-history"]);
  }, []);

  const events = {
    nftUpdate: handleNftUpdate,
  };

  useWebSocket(events);

  return (
    <>
      <div
        id="cesiumContainer"
        className={styles.cesiumContainer}
        ref={cesiumContainerRef}
      />
      <SwitchPlanet />
    </>
  );
};

export default ViewerContainer;
