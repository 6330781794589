import { clsx } from "clsx";
import PropTypes from "prop-types";

import { Loader } from "@components/Loader";

import styles from "./Button.module.css";

const Button = ({
  onClick,
  main = false,
  loading = false,
  disabled = false,
  className,
  children,
  ...props
}) => {
  return (
    <button
      className={clsx(
        styles.button,
        main ? styles.main : styles.outlined,
        className
      )}
      onClick={onClick}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? <Loader size="xs" /> : children}
    </button>
  );
};

Button.propTypes = {
  onClick: PropTypes.func,
  main: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Button;
