import PropTypes from "prop-types";

import user from "@icons/edit-user.svg";

import styles from "./UserInfo.module.css";

const UserInfo = ({ data, toggleUserProfile }) => {
  return (
    <div className={styles.userContent}>
      {data?.bio && <p>{data.bio}</p>}
      <button onClick={toggleUserProfile}>
        <span className={styles.username}>
          {data?.username || "Unnamed user"}
        </span>
        <img className={styles.userIcon} src={data?.image || user} />
      </button>
      {data?.email && (
        <a href={`mailto:${data?.email}`} className={styles.userEmail}>
          {data?.email}
        </a>
      )}
    </div>
  );
};

UserInfo.propTypes = {
  data: PropTypes.shape({
    username: PropTypes.string,
    bio: PropTypes.string,
    email: PropTypes.string,
    image: PropTypes.string,
  }),
  toggleUserProfile: PropTypes.func,
};

export default UserInfo;
