import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";

import { clsx } from "@shared/helpers";

import DocumentIcon from "@icons/Document.svg";

import styles from "./UploadFile.module.css";
import useFileUpload from "./hooks/useFileUpload";

const UploadFile = ({
  name,
  label,
  acceptedFiles,
  defaultFile,
  maxSize,
  control,
  rules,
  required = false,
  error,
  setFileValue,
  disabled = false,
}) => {
  const fileInputRef = useRef(null);

  const {
    preview,
    fileDetails,
    fileError,
    dragging,
    uploadProgress,
    setPreview,
    onFileChange,
    onDragOver,
    onDragLeave,
    onDrop,
  } = useFileUpload({
    acceptedFiles,
    maxSize,
    onFileSelected: (file) => {
      setFileValue(name, file);
    },
  });

  useEffect(() => {
    if (defaultFile) {
      setPreview(defaultFile);
    }
  }, [defaultFile, setPreview]);

  return (
    <div className={styles.container}>
      <fieldset
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        className={clsx(styles.fieldset, dragging && styles.dragging)}
      >
        <legend
          className={clsx(styles.fieldsetLegend, required && styles.required)}
        >
          {label}
        </legend>
        <Controller
          control={control}
          name={name}
          defaultValue=""
          rules={rules}
          render={({ field: { value, onChange, ...field } }) => (
            <input
              {...field}
              id={name}
              ref={fileInputRef}
              type="file"
              accept={acceptedFiles.join(",")}
              value={value?.fileName}
              onChange={(e) => {
                const error = onFileChange(e);
                if (error === true) {
                  onChange(null);
                  return;
                }
                onChange(e.target.files[0]);
              }}
              disabled={disabled}
            />
          )}
        />

        <label
          className={clsx(styles.button, disabled && styles.disabled)}
          htmlFor={name}
        >
          {!preview && "Drag and drop here or click to browse"}
        </label>
        {preview &&
          (fileDetails.type === ".glb" ||
            fileDetails.type === ".gltf" ||
            preview.endsWith(".glb")) && (
            <model-viewer
              src={preview}
              alt="A 3D model"
              auto-rotate
              camera-controls
              shadowIntensity={1}
              touchAction="pan-y"
            />
          )}
        {preview &&
          (fileDetails.type.startsWith("image/") ||
            /\.(gif|jpg|jpeg|tiff|png|webp)$/i.test(preview)) && (
            <img
              src={preview}
              alt="Preview"
              className={clsx(
                styles.uploadImagePreview,
                disabled && styles.disabled
              )}
            />
          )}
      </fieldset>
      {preview &&
        !disabled &&
        (fileDetails.type === ".glb" ||
          fileDetails.type === ".gltf" ||
          preview.endsWith(".glb")) && (
          <button
            className={styles.changeModelButton}
            type="button"
            onClick={() => fileInputRef.current.click()}
            disabled={disabled}
          >
            Change
          </button>
        )}
      {(fileError || error) && (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{fileError || error}</span>
        </div>
      )}
      {preview && !preview.startsWith("https://") ? (
        <div className={styles.uploadProgress}>
          <img
            className={styles.progressDocumentIcon}
            src={DocumentIcon}
            alt="Document icon"
          />
          {uploadProgress !== 100 ? (
            <div className={styles.progressBarContainer}>
              <span
                className={styles.progressBar}
                style={{ width: `${uploadProgress}%` }}
              />
              <span className={styles.percentageNumber}>
                {uploadProgress || 0}%
              </span>
            </div>
          ) : (
            <div className={styles.fileDetails}>
              <span title={fileDetails.name}>{fileDetails.name}</span>
              <span>{fileDetails.size}</span>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

UploadFile.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  acceptedFiles: PropTypes.arrayOf(PropTypes.string),
  defaultFile: PropTypes.string,
  maxSize: PropTypes.number,
  required: PropTypes.bool,
  control: PropTypes.object,
  setFileValue: PropTypes.func,
  rules: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default UploadFile;
