import { useQueryData } from "@api/hooks";
import { polygonService } from "@api/services";

const usePolygonsQuery = (config) => {
  const { data, ...restOfQueryParams } = useQueryData(
    ["polygons"],
    async () => await polygonService.getPolygons(),
    { ...config }
  );

  return {
    polygons: data,
    ...restOfQueryParams,
  };
};

export { usePolygonsQuery };
