import PropTypes from "prop-types";

import { useErc20Balance, useNativeBalance } from "@Web3/hooks";

import { truncateAddress } from "@shared/helpers";

import walletDisconnect from "@icons/disconnect-wallet.svg";
import PolygonIcon from "@icons/polygon.svg";
import WrappedMatic from "@icons/wrapped-matic.svg";

import styles from "./WalletInfo.module.css";

const WalletInfo = ({ address, onDisconnect }) => {
  const { data: nativeBalance } = useNativeBalance();
  const { data: erc20Balance } = useErc20Balance();

  return (
    <div className={styles.walletContent}>
      <div className={styles.balanceTitle}>Wallet balance:</div>
      <div className={styles.balance}>
        <div className={styles.token}>
          <img width={27} height={27} src={PolygonIcon} alt="Poligon icon" />
          <span>{`${nativeBalance?.formatted || 0} POL`}</span>
        </div>
        <span className={styles.usd}>(${nativeBalance?.usd || 0})</span>
      </div>
      <div className={styles.balanceTitle}>Offer balance:</div>
      <div className={styles.balance}>
        <div className={styles.token}>
          <img width={23} src={WrappedMatic} alt="Wrapped Pol icon" />
          <span>{erc20Balance?.formatted || 0} WPOL</span>
        </div>
        <span className={styles.usd}>(${erc20Balance?.usd || 0})</span>
      </div>
      <p className={styles.walletAddress}>
        Wallet address: {truncateAddress(address)}
      </p>
      <button onClick={onDisconnect} className={styles.disconnectWallet}>
        Disconnect wallet
      </button>
      <span className={styles.disconnectIcon} />
      <img className={styles.imageDisconnect} src={walletDisconnect} />
    </div>
  );
};

WalletInfo.propTypes = {
  balance: PropTypes.any,
  currency: PropTypes.string,
  address: PropTypes.string,
  usd: PropTypes.any,
  onDisconnect: PropTypes.func,
};

export default WalletInfo;
