import { format } from "date-fns";
import PropTypes from "prop-types";

import Modal from "@components/WindowModal";

import styles from "./WelcomeNotification.module.css";

const WelcomeNotification = ({ onClose }) => {
  return (
    <Modal
      isOpen={true}
      onClose={onClose}
      title="Welcome to Decentralverse AI!"
      subtitle={format(new Date(), "yyyy-MM-dd HH:mm:ss")}
      modalClassName={styles.welcomeModal}
      headerDivider
      confirmButton={{
        text: "Explore the App",
        onClick: onClose,
      }}
    >
      <div className={styles.content}>
        <p className={styles.text}>
          We&apos;re thrilled to have you join our community. Explore, learn,
          and innovate with the power of decentralized AI. We&apos;re excited to
          see what you&apos;ll create! 🚀
        </p>
        <div className={styles.greetings}>
          <p>Happy exploring!</p>
          <p>The Decentralverse AI Team</p>
        </div>
      </div>
    </Modal>
  );
};

WelcomeNotification.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default WelcomeNotification;
