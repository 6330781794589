import { differenceInSeconds, parseISO } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";
import { useEffect, useState } from "react";

const useCountdown = (targetDate, timeZone) => {
  const zonedTargetDate = utcToZonedTime(parseISO(targetDate), timeZone);

  const [secondsLeft, setSecondsLeft] = useState(() =>
    differenceInSeconds(zonedTargetDate, new Date())
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setSecondsLeft(differenceInSeconds(zonedTargetDate, new Date()));
    }, 1000);

    return () => clearInterval(interval);
  }, [zonedTargetDate]);

  const days = Math.floor(secondsLeft / (3600 * 24));
  const hours = Math.floor((secondsLeft % (3600 * 24)) / 3600);
  const minutes = Math.floor((secondsLeft % 3600) / 60);
  const seconds = secondsLeft % 60;

  return [days, hours, minutes, seconds];
};

export default useCountdown;
