import config from "@config";
import { toQuantity } from "ethers";
import { useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";

import { useUserQuery } from "@api/user/hooks";

import { getCachedConnector } from "@Web3/connectors/connectorCache";
import { WALLET_CONNECT, Web3WalletEvent } from "@Web3/constants";
import { useWalletActions, useWalletState } from "@Web3/context";
import { WalletErrorHandler } from "@Web3/utils";

const useWithConnectedWallet = (fn) => {
  const argumentsRef = useRef();

  const { chainId, isCorrectChain, currentAddress, web3WalletEvent } =
    useWalletState();
  const {
    toggleWalletConnectModal,
    switchChain,
    disconnect,
    updateWeb3WalletEvents,
  } = useWalletActions();
  const { user } = useUserQuery();

  const executeCallback = useCallback(async () => {
    if (argumentsRef.current) {
      try {
        await fn(...argumentsRef.current);
        updateWeb3WalletEvents(Web3WalletEvent.WALLET_TRANSACTION_SUCCESS);
      } catch (error) {
        updateWeb3WalletEvents(Web3WalletEvent.WALLET_TRANSACTION_ERROR);
        const errorMessage = WalletErrorHandler(error);
        toast.error(errorMessage);
      }
    }
  }, [fn, updateWeb3WalletEvents]);

  const handleChainSwitch = useCallback(async () => {
    const connector = getCachedConnector();

    if (connector?.id === WALLET_CONNECT && !isCorrectChain) {
      toast.warn(
        `Please add or switch to ${config.blockchain.chainName} in your wallet before proceeding`
      );
      return;
    }

    const chainName = config.blockchain.chainName;

    const onSuccessfulChainSwitch = async () => {
      await executeCallback();
    };

    return await switchChain(
      toQuantity(parseInt(config.blockchain.chainId)),
      chainName,
      onSuccessfulChainSwitch
    );
  }, [executeCallback, isCorrectChain, switchChain]);

  useEffect(() => {
    if (
      currentAddress &&
      user?.walletAddress?.toLowerCase() === currentAddress.toLowerCase() &&
      web3WalletEvent === Web3WalletEvent.CONNECT_SUCCESS
    ) {
      executeCallback();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAddress, user?.walletAddress, web3WalletEvent]);

  const executeAction = useCallback(
    (...args) => {
      argumentsRef.current = args;

      if (!currentAddress) {
        toggleWalletConnectModal();
        return;
      }

      if (!user) {
        disconnect();
        return;
      }

      if (chainId && !isCorrectChain) {
        handleChainSwitch();
      } else {
        executeCallback();
      }
    },
    [
      chainId,
      currentAddress,
      disconnect,
      executeCallback,
      handleChainSwitch,
      isCorrectChain,
      toggleWalletConnectModal,
      user,
    ]
  );

  return executeAction;
};

export default useWithConnectedWallet;
