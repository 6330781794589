import PropTypes from "prop-types";

import Loader from "./Loader";
import styles from "./Loader.module.css";

const PageLoader = ({ size = "xl", backgroundColor, text = "" }) => {
  return (
    <div
      style={{ background: backgroundColor }}
      className={styles.loaderContainer}
    >
      <div className={styles.centeredContent}>
        <Loader size={size} />
        <div className={styles.loaderMessage}>{text}</div>
      </div>
    </div>
  );
};

PageLoader.propTypes = {
  size: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs"]),
  backgroundColor: PropTypes.string,
  text: PropTypes.string,
};

export default PageLoader;
