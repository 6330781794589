import config from "@config/index";
import { MaxUint256, parseUnits } from "ethers";
import { useCallback } from "react";

import { queryClient } from "@api/queryClient";

import { Erc20ContractMethods } from "@Web3/constants";
import { useWalletState } from "@Web3/context";
import useAllowance from "@Web3/hooks/useAllowance";
import { WalletErrorHandler } from "@Web3/utils";

import useErc20Contract from "@contracts/hooks/useErc20Contract";

import useErc20Balance from "./useErc20Balance";

const useApprove = () => {
  const { currentAddress } = useWalletState();
  const erc20Contract = useErc20Contract();
  const { data: allowance } = useAllowance();
  const { data: balance } = useErc20Balance();

  const approveFundsTransfer = useCallback(
    async ({ amount }) => {
      if (!currentAddress) {
        throw new Error("Connect your wallet to proceed");
      }

      try {
        const amountBigNumber = parseUnits(amount.toString());

        if (!balance || balance.wei < amountBigNumber) {
          throw new Error("Insufficient funds");
        }

        if (allowance && allowance >= amountBigNumber) {
          return;
        }

        const tx = await erc20Contract.approve(
          config.contracts.dcvExchangeAddress,
          MaxUint256
        );
        await tx.wait();

        queryClient.invalidateQueries({
          queryKey: [Erc20ContractMethods.ALLOWANCE],
        });

        return tx.hash;
      } catch (error) {
        const errorMessage = WalletErrorHandler(error);

        throw new Error(errorMessage);
      }
    },
    [allowance, balance, currentAddress, erc20Contract]
  );

  return {
    approveFundsTransfer,
  };
};

export default useApprove;
