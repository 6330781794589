import clsx from "clsx";
import propTypes from "prop-types";

import envelopeOpen from "@icons/envelope-open.svg";
import envelope from "@icons/envelope.svg";
import linkArrow from "@icons/link-arrow.svg";

import styles from "./Notification.module.css";

const Notification = ({ title, date, isRead, onClick }) => {
  return (
    <button className={styles.notification} onClick={onClick}>
      <img
        className={styles.notificationIcon}
        src={isRead ? envelopeOpen : envelope}
        alt={isRead ? "envelope open" : "envelope"}
      />
      <div className={styles.notificationMessage}>
        {title && (
          <h3 className={clsx(styles.notificationTitle, isRead && styles.read)}>
            {title}
          </h3>
        )}
        {date && (
          <div className={styles.notificationDate}>
            <span>{date}</span>
            <img width={10} height={10} src={linkArrow} alt="link arrow" />
          </div>
        )}
      </div>
    </button>
  );
};

Notification.propTypes = {
  title: propTypes.string,
  date: propTypes.string,
  isRead: propTypes.bool,
  onClick: propTypes.func,
};

export default Notification;
