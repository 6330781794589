import PropTypes from "prop-types";

import Modal from "@components/Modal";

import LandIcon from "@icons/land.svg";

import styles from "./ImportLandModal.module.css";

const CreateLandModal = ({ onClose }) => {
  return (
    <Modal
      title="You don't have Land"
      className={styles.modal}
      onClose={onClose}
      size="small"
    >
      <div className={styles.image}>
        <img width={85} height={85} src={LandIcon} alt="Square icon" />
      </div>
      <p className={styles.text}>
        You don&apos;t have any land yet, explore, create and buy land with land
        tool.
      </p>

      <button className={styles.button} onClick={onClose}>
        Dismiss
      </button>
    </Modal>
  );
};

CreateLandModal.propTypes = {
  landName: PropTypes.string,
  onImport: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateLandModal;
