import {
  booleanPointInPolygon,
  booleanWithin as turfBooleanWithin,
  kinks as turfKinks,
  lineIntersect as turfLineIntersect,
  lineOverlap as turfLineOverlap,
  lineString as turfLineString,
  point as turfPoint,
  polygon as turfPolygon,
} from "@turf/turf";
import { Cartographic as cesiumCartographic, Math as cesiumMath } from "cesium";

import { TEMP_POLYGON_ID } from "../constants";

// Helper function to get existing polygons from the viewer
const getExistingPolygons = (viewer) => {
  return viewer.entities.values
    .filter((entity) => entity.polygon && entity.id !== TEMP_POLYGON_ID)
    .map((entity) => {
      const polygonHierarchy = entity.polygon.hierarchy.getValue();
      const polygonCoords = polygonHierarchy.positions.map((pos) => {
        const cartographic = cesiumCartographic.fromCartesian(pos);
        const longitude = cesiumMath.toDegrees(cartographic.longitude);
        const latitude = cesiumMath.toDegrees(cartographic.latitude);
        return [longitude, latitude];
      });
      return turfPolygon([polygonCoords]);
    });
};

// Check if a point is inside any existing polygon
export const isPointInsideExistingPolygon = (viewer, pointCoordinates) => {
  const point = turfPoint(...pointCoordinates);
  const existingPolygons = getExistingPolygons(viewer);

  for (const existingPolygon of existingPolygons) {
    if (
      booleanPointInPolygon(point, existingPolygon, { ignoreBoundary: true })
    ) {
      return true;
    }
  }
  return false;
};

// Check if a line intersects with any existing polygon
export const isLineIntersectingExistingPolygon = (viewer, lineString) => {
  const existingPolygons = getExistingPolygons(viewer);

  for (const existingPolygon of existingPolygons) {
    const boundaryLineStrings = existingPolygon.geometry.coordinates[0].map(
      (coords, i, arr) => {
        const nextIndex = (i + 1) % arr.length;
        return turfLineString([coords, arr[nextIndex]]);
      }
    );

    for (const boundaryLineString of boundaryLineStrings) {
      const overlap = turfLineOverlap(lineString, boundaryLineString);
      const intersectionPoints = turfLineIntersect(
        lineString,
        boundaryLineString
      );

      if (turfBooleanWithin(lineString, existingPolygon)) {
        return true;
      } else if (
        intersectionPoints.features.length > 0 &&
        intersectionPoints.features.length > overlap.features.length
      ) {
        const hasVertexIntersection = intersectionPoints.features.some(
          (feature) => {
            const [x, y] = feature.geometry.coordinates;
            return existingPolygon.geometry.coordinates[0].some(
              ([px, py]) => x === px && y === py
            );
          }
        );

        if (!hasVertexIntersection) {
          return true;
        }
      }
    }
  }

  return false;
};

// Check if a polygon is self-intersecting
export const isPolygonSelfIntersecting = (
  polygonCoords,
  nonActiveLinesString
) => {
  polygonCoords.pop();
  polygonCoords.push(polygonCoords[0]);

  const withClosingLineString = turfLineString(polygonCoords);

  const activeLineKinks = turfKinks(nonActiveLinesString);
  const closingLineKinks = turfKinks(withClosingLineString);

  return (
    activeLineKinks.features.length > 0 || closingLineKinks.features.length > 0
  );
};

// Check if the new polygon covers any existing polygon
export const isPolygonCoveringExistingPolygon = (viewer, newPolygonCoords) => {
  // Ensure the new polygon coordinates form a closed loop
  if (newPolygonCoords.length <= 3) {
    return false;
  }

  // Create a copy of the coordinates array to avoid mutating the original
  const newCoordsCopy = [...newPolygonCoords];

  if (
    newCoordsCopy[0][0] !== newCoordsCopy[newCoordsCopy.length - 1][0] ||
    newCoordsCopy[0][1] !== newCoordsCopy[newCoordsCopy.length - 1][1]
  ) {
    newCoordsCopy.push(newCoordsCopy[0]);
  }

  const newPolygon = turfPolygon([newCoordsCopy]);
  const existingPolygons = getExistingPolygons(viewer);

  for (const existingPolygon of existingPolygons) {
    if (turfBooleanWithin(existingPolygon, newPolygon)) {
      return true;
    }
  }
  return false;
};
