import detectEthereumProvider from "@metamask/detect-provider";

import { METAMASK } from "@Web3/constants";
import { WalletErrorHandler } from "@Web3/utils";

const METAMASK_DOWNLOAD_URL = "https://metamask.io/download.html";

const connect = async () => {
  const detected = await detectEthereumProvider();
  const provider =
    detected?.providers?.find(({ isMetaMask }) => isMetaMask) || detected;

  if (!provider) {
    return window.open(METAMASK_DOWNLOAD_URL, "_blank");
  }

  try {
    await provider.request({ method: "eth_requestAccounts" });
    return provider;
  } catch (error) {
    const errorMessage = WalletErrorHandler(error);
    console.log({ errorMessage, di: "metamask connector" });
    throw new Error(errorMessage);
  }
};

const connector = { id: METAMASK, connect };

export default connector;
