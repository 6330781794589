import {
  ClassificationType,
  HeightReference,
  Cartesian3 as cesiumCartesian3,
  Color as cesiumColor,
  Entity as cesiumEntity,
  PointGraphics as cesiumPointGraphics,
  PolygonHierarchy as cesiumPolygonHierarchy,
} from "cesium";
import { useCallback } from "react";

const drawPolygon = ({ entities, id, ownerId, coordinates, color, alpha }) => {
  const existingPolygon = entities.getById(`polygon-${id}`);

  if (existingPolygon) {
    return;
  }

  const cesiumCoordinates = coordinates.map(([latitude, longitude]) =>
    cesiumCartesian3.fromDegrees(longitude, latitude)
  );

  const polygonEntity = new cesiumEntity({
    id: `polygon-${id}`,
    properties: { ownerId: ownerId },
    polyline: {
      positions: cesiumCoordinates,
      width: 4,
      material: cesiumColor.fromCssColorString("#F7BB3E").withAlpha(1),
      clampToGround: true,
      classificationType: ClassificationType.TERRAIN,
    },
    polygon: {
      hierarchy: new cesiumPolygonHierarchy(cesiumCoordinates),
      material: cesiumColor.fromCssColorString(color).withAlpha(alpha),
      clampToGround: true,
      classificationType: ClassificationType.TERRAIN,
    },
  });

  entities.add(polygonEntity);

  cesiumCoordinates.forEach((coordinate, index) => {
    const existingPoint = entities.getById(`polygon-point-${id}-${index}`);
    if (existingPoint) {
      return;
    }
    const pointEntity = new cesiumEntity({
      id: `polygon-point-${id}-${index}`,
      position: coordinate,
      point: new cesiumPointGraphics({
        color: cesiumColor.WHITE,
        pixelSize: 2,
        heightReference: HeightReference.CLAMP_TO_GROUND,
      }),
    });

    entities.add(pointEntity);
  });
};

const usePolygons = (viewer) => {
  const loadDatabasePolygonsInViewer = useCallback(
    (polygons) => {
      if (!viewer?.entities || polygons?.length === 0) {
        return;
      }

      polygons?.forEach((polygon) => {
        const id = polygon.id;
        const coordinates = polygon.vertices;
        const color = polygon.color;
        const alpha = polygon.alpha;
        const ownerId = polygon.ownerId;
        drawPolygon({
          entities: viewer.entities,
          ownerId,
          id,
          coordinates,
          color,
          alpha,
        });
      });
    },
    [viewer?.entities]
  );

  const togglePolygonsVisibility = useCallback(
    (showPolygons) => {
      if (viewer?.entities?.values) {
        const entities = viewer.entities.values;

        for (const entity of entities) {
          if (
            (entity.id && entity.id.startsWith("polygon-")) ||
            entity.id.startsWith(`polygon-point-`)
          ) {
            entity.show = showPolygons;
          }
        }
      }
    },
    [viewer?.entities?.values]
  );

  return { togglePolygonsVisibility, loadDatabasePolygonsInViewer };
};

export { usePolygons };
