const polygonRoutes = {
  getPolygonLocation: () => "/api/polygons/location",
  getLocationTypeShares: () => "/api/polygons/location-type-shares",
  getPolygons: () => "/api/polygons",
  getPolygon: (identifier) => `/api/polygons/${identifier}`,
  createPolygon: () => "/api/polygons",
  updatePolygon: (identifier) => `/api/polygons/${identifier}`,
  deletePolygon: (identifier) => `/api/polygons/${identifier}`,
};

export { polygonRoutes };
