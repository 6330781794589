import PropTypes from "prop-types";

import { useOwnedNftsQuery } from "@api/nft/hooks";
import { useUserQuery } from "@api/user/hooks";

import Map from "@components/Map";

import styles from "./Maps.module.css";

const Maps = ({ userId }) => {
  const { nfts, ownedPolygonsCount, ownedAssetsCount } =
    useOwnedNftsQuery(userId);

  const { user } = useUserQuery();

  return (
    <div className={styles.mapContent}>
      <h3>{`MY LANDS${
        ownedPolygonsCount ? "(" + ownedPolygonsCount + ")" : ""
      }/3D ASSETS${ownedAssetsCount ? "(" + ownedAssetsCount + ")" : ""}`}</h3>
      {nfts && nfts.length !== 0 ? (
        <div className={styles.landScroll}>
          {nfts.map(
            ({
              id,
              name,
              imageUrl,
              thumbnailUrl,
              assets,
              vertices,
              ownerId,
            }) => (
              <Map
                key={id}
                landName={name}
                landImage={thumbnailUrl || imageUrl}
                landVertices={vertices}
                landOwnerId={ownerId}
                asset={assets[0]}
                userId={user?.id}
              />
            )
          )}
        </div>
      ) : (
        <Map />
      )}
    </div>
  );
};

Maps.propTypes = {
  userId: PropTypes.string,
};

export default Maps;
