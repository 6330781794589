import fetchData from "@api/axios";

const assetService = {
  async getAssetTypes() {
    return await fetchData({
      routeKey: "getAssetTypes",
    });
  },
  async getAssetTypeShares() {
    return await fetchData({
      routeKey: "getAssetTypeShares",
    });
  },
  async getAssets() {
    return await fetchData({
      routeKey: "getAssets",
    });
  },
  async getAsset(identifier) {
    return await fetchData({
      routeKey: "getAsset",
      params: identifier,
    });
  },
  async createAsset(data) {
    return await fetchData({
      routeKey: "createAsset",
      method: "POST",
      data,
    });
  },
  async updateAsset({ identifier, data }) {
    return await fetchData({
      routeKey: "updateAsset",
      method: "PUT",
      params: identifier,
      data,
    });
  },
  async updateAssetPosition({ identifier, data }) {
    return await fetchData({
      routeKey: "updateAssetPosition",
      method: "PUT",
      params: identifier,
      data,
    });
  },
  async deleteAsset(identifier) {
    return await fetchData({
      routeKey: "deleteAsset",
      method: "DELETE",
      params: identifier,
    });
  },
};

export { assetService };
