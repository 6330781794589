import { centroid as turfCentroid, polygon as turfPolygon } from "@turf/turf";
import { Cartographic as cesiumCartographic, Math as cesiumMath } from "cesium";

export const getPolygonCoordinates = (positions) => {
  const coordinates = positions.map((pos) => {
    const cartographic = cesiumCartographic.fromCartesian(pos);
    const longitude = cesiumMath.toDegrees(cartographic.longitude);
    const latitude = cesiumMath.toDegrees(cartographic.latitude);
    return [latitude, longitude];
  });

  // Calculate centroid
  const polygonFeature = turfPolygon([coordinates]);
  const centroid = turfCentroid(polygonFeature);
  const [centroidLatitude, centroidLongitude] = centroid.geometry.coordinates;

  return {
    centroidCoordinates: [centroidLatitude, centroidLongitude],
    verticesCoordinates: coordinates,
  };
};
