export const TEMP_POLYGON_ID = "temp-polygon";

export const MintStage = {
  INITIATED: "INITIATED",
  AWAITING_CONFIRMATION: "AWAITING_CONFIRMATION",
  CONFIRMING: "CONFIRMING",
  COMPLETED: "COMPLETED",
};

export const TradeStage = {
  INITIATED: "INITIATED",
  AWAITING_CONFIRMATION: "AWAITING_CONFIRMATION",
  CONFIRMING: "CONFIRMING",
  COMPLETED: "COMPLETED",
};

export const TOAST_IDS = {
  POLYGON_TOO_LARGE: "polygon-too-large",
  POINT_INSIDE_EXISTING: "point-inside-existing",
  LINE_INTERSECT_EXISTING: "line-intersect-existing",
  POLYGON_COVERS_EXISTING: "polygon-covers-existing",
  SELF_INTERSECTION: "self-intersection",
  MIN_POINTS_REQUIRED: "min-points-required",
  POLYGON_TOO_SMALL: "polygon-too-small",
  GET_CLOSER: "get-closer",
};
