import PropTypes from "prop-types";

import Modal from "@components/Modal";

import { clsx } from "@shared/helpers";

import SquareIcon from "@icons/land-square.svg";

import styles from "./Import3dAssetModal.module.css";

const Import3dAssetModal = ({ landName, onImport, onClose }) => {
  return (
    <Modal
      title="Import 3D asset"
      className={styles.modal}
      onClose={onClose}
      size="small"
    >
      <div className={styles.image}>
        <img width={85} height={85} src={SquareIcon} alt="Square icon" />
      </div>
      <p className={styles.text}>
        {landName
          ? `You don't have any 3D asset on your land "${landName}". Please, import one.`
          : "You don't have any land yet. You need to buy land first and then you can import your 3D asset on it."}
      </p>
      {landName ? (
        <div className={styles.ctaButtons}>
          <button
            className={clsx(styles.button, styles.cancelButton)}
            onClick={onClose}
          >
            Cancel
          </button>
          <button className={styles.button} onClick={onImport}>
            Continue
          </button>
        </div>
      ) : (
        <button className={styles.button} onClick={onClose}>
          Dismiss
        </button>
      )}
    </Modal>
  );
};

Import3dAssetModal.propTypes = {
  landName: PropTypes.string,
  onImport: PropTypes.func,
  onClose: PropTypes.func,
};

export default Import3dAssetModal;
