const formatNumber = (number, maxDigits = 2) => {
  if (number === undefined || number === null) {
    return;
  }
  if (number === 0) {
    return number;
  }
  return number.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: maxDigits,
  });
};

export { formatNumber };
