export const HELP_LINKS = [
  {
    id: 1,
    label: "Setting up a wallet",
    url: "https://docs.decentralverse.io/guides/setting-up-a-wallet-with-crypto",
  },
  {
    id: 2,
    label: "How to buy land",
    url: "https://docs.decentralverse.io/getting-started/how-to-buy-land",
  },
  {
    id: 3,
    label: "Import 3D Assets",
    url: "https://docs.decentralverse.io/getting-started/import-3d-assets",
  },
  {
    id: 4,
    label: "FAQs",
    url: "https://docs.decentralverse.io/getting-started/faqs",
  },
  {
    id: 5,
    label: "Whitepaper",
    url: "https://docs.decentralverse.io/whitepaper-1.0/introduction",
  },
  {
    id: 6,
    label: "AI-Generated planets",
    url: "https://docs.decentralverse.io/ai-generated-planets/create-your-own-planets",
  },
  {
    id: 7,
    label: "Metaverse Architects",
    url: "https://docs.decentralverse.io/metaverse-architecture/metaverse-architects",
  },
  {
    id: 8,
    label: "The Team",
    url: "https://docs.decentralverse.io/references/the-team",
  },
  {
    id: 9,
    label: "Roadmap",
    url: "https://docs.decentralverse.io/whitepaper-1.0/roadmap",
  },
  {
    id: 10,
    label: "Blog",
    url: "https://www.decentralverse.io/blog/",
  },
  {
    id: 11,
    label: "Contact Us",
    url: "https://docs.decentralverse.io/references/contact-us",
  },
];
