const authRoutes = {
  createJwt: () => `/api/auth`,
  getJwt: (identifier) => `/api/auth/${identifier}`,
  deleteJwt: (identifier) => `/api/auth/${identifier}`,
  sendVerificationCode: () => "/api/auth/send_verification_code",
  verifyCode: () => "/api/auth/verify_code",
  checkUsername: () => "/api/auth/check_username",
  checkEmail: () => "/api/auth/check_email",
};

export { authRoutes };
