import { assetRoutes } from "./asset/routes";
import { authRoutes } from "./auth/routes";
import { contractRoutes } from "./contract/routes";
import { fileRoutes } from "./file/routes";
import { listingRoutes } from "./listing/routes";
import { nftRoutes } from "./nft/routes";
import { polygonRoutes } from "./polygon/routes";
import { presaleRoutes } from "./presale/routes";
import { tradeRoutes } from "./trade/routes";
import { userRoutes } from "./user/routes";
import { whitelistRoutes } from "./whitelist/routes";

const routes = {
  ...authRoutes,
  ...assetRoutes,
  ...userRoutes,
  ...polygonRoutes,
  ...fileRoutes,
  ...listingRoutes,
  ...tradeRoutes,
  ...nftRoutes,
  ...whitelistRoutes,
  ...contractRoutes,
  ...presaleRoutes,
};

export default routes;
