import { useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";

const useWebSocket = (events = {}) => {
  const socketRef = useRef(null);
  const [connected, setConnected] = useState(false);

  const memoizedEvents = useRef(events);

  useEffect(() => {
    memoizedEvents.current = events;
  }, [events]);

  useEffect(() => {
    const connectWebSocket = () => {
      socketRef.current = io("/", {
        path: "/socket.io",
        transports: ["websocket"],
        upgrade: false,
      });

      socketRef.current.on("connect", () => {
        console.log("WebSocket connected:", socketRef.current.id);
        setConnected(true);
      });

      socketRef.current.on("connect_error", (error) => {
        console.error("WebSocket connection error:", error);
      });

      socketRef.current.on("disconnect", (reason) => {
        console.log("WebSocket disconnected:", reason);
        setConnected(false);
      });

      Object.entries(memoizedEvents.current).forEach(([event, handler]) => {
        socketRef.current?.on(event, (data) => {
          console.log(`WebSocket event received: ${event}`, data);
          handler(data);
        });
      });
    };

    connectWebSocket();

    return () => {
      if (socketRef.current) {
        Object.keys(memoizedEvents.current).forEach((event) => {
          socketRef.current?.off(event);
        });
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  return { connected };
};

export default useWebSocket;
