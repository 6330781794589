import PropTypes from "prop-types";

import { clsx } from "@shared/helpers";

import styles from "./CheckBox.module.css";

const CheckBox = ({ id, name, label, required = false, register, error }) => {
  return (
    <>
      <div className={styles.checkboxContainer}>
        <input
          type="checkbox"
          name={name}
          id={id}
          className={styles.checkboxInput}
          {...register}
        />
        <label
          htmlFor={id}
          className={clsx(styles.checkboxLabel, required && styles.required)}
        >
          {label}
        </label>
      </div>
      {error && (
        <div className={styles.errorContainer}>
          <span className={styles.error}>{error}</span>
        </div>
      )}
    </>
  );
};

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
  register: PropTypes.object.isRequired,
  error: PropTypes.string,
};

export default CheckBox;
