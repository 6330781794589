import PropTypes from "prop-types";

import { clsx } from "@shared/helpers";

import styles from "./Countdown.module.css";
import useCountdown from "./hooks/useCountdown";

const Countdown = ({ targetDate, timeZone, className }) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate, timeZone);

  return (
    <div className={clsx(styles.countdownWrapper, className)}>
      <h5 className={styles.text}>Countdown to the public Launch</h5>
      <span className={styles.counter}>
        {days} days : {hours} hrs : {minutes} min : {seconds} sec
      </span>
    </div>
  );
};

Countdown.propTypes = {
  targetDate: PropTypes.string.isRequired,
  timeZone: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Countdown;
