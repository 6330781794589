import fetchData from "@api/axios";

const contractService = {
  async generateMintSignature(data) {
    return await fetchData({
      routeKey: "generateMintSignature",
      method: "POST",
      data,
    });
  },

  async generateRoyaltySignature(data) {
    return await fetchData({
      routeKey: "generateRoyaltySignature",
      method: "POST",
      data,
    });
  },

  async generateOfferSignature(data) {
    return await fetchData({
      routeKey: "generateOfferSignature",
      method: "POST",
      data,
    });
  },
};

export { contractService };
