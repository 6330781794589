// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SLTZUxk6 {
  position: absolute;
  display: flex;
  bottom: 0%;
  left: 98.5%;
  transform: translate(-95%, -10%);
  justify-content: center;
  align-items: center;
  width: 121px;
  height: 121px;
  opacity: 0.5;
}

.p7Xi3NQy {
  position: relative;
  margin-right: 0.5rem;
}

.kbnBOfPW {
  width: 41.14px;
  height: 41.14px;
  background: none;
  border: none;
  position: absolute;
  opacity: 0.5;
  left: 50%;
  transform: translate(-75%, 25%);
}

.kbnBOfPW > img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.SLTZUxk6:hover {
  opacity: 1;
}

.zVCmBkcs {
  position: absolute;
  bottom: 15%;
  right: 1.2%;
  background-color: #010519;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 260px;
}

.C3lcmvsy {
  font-size: 1rem;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  margin: 0 1rem;
}

.N_ht3dmC {
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 800;
  font-size: 1.75rem;
  line-height: 2.25rem;
}

.WCZxul7h {
  border: none;
  background: none;
  cursor: pointer;
  font-weight: 400;
  position: relative;
  top: -0.25rem;
}

.c0ic4YUm {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}

.c0ic4YUm:hover {
  text-decoration: none;
  background: rgba(108, 109, 114, 0.25);
}

.xpChpzQF {
  color: white;
  display: flex;
  text-align: left;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;
}

.xpChpzQF > span:first-of-type {
  line-height: 1.375rem;
}

.yZ3WuGf3 {
  margin-right: 0.7rem;
}

.yfGIG0nl {
  position: absolute;
}

.yfGIG0nl.xmWC7ojZ {
  top: 1px;
  left: -2px;
}

.yfGIG0nl.DzA57ZyK {
  top: -8px;
  right: 3px;
}

.yfGIG0nl.OCiJwsSp {
  bottom: 1px;
  right: -2px;
}

.CSJLyXqk {
  display: inline-block;
  white-space: nowrap;
  font-size: 0.625rem;
  font-weight: 600;
}

.JT03ztz1 {
  width: 100%;
  text-align: left;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `SLTZUxk6`,
	"aiGeneratedPlanetsIcons": `p7Xi3NQy`,
	"planetIcon": `kbnBOfPW`,
	"modal": `zVCmBkcs`,
	"modalHeader": `C3lcmvsy`,
	"modalTitle": `N_ht3dmC`,
	"closeButton": `WCZxul7h`,
	"modalContent": `c0ic4YUm`,
	"planets": `xpChpzQF`,
	"modalPlanetIcon": `yZ3WuGf3`,
	"star": `yfGIG0nl`,
	"big": `xmWC7ojZ`,
	"medium": `DzA57ZyK`,
	"small": `OCiJwsSp`,
	"comingSoon": `CSJLyXqk`,
	"button": `JT03ztz1`
};
export default ___CSS_LOADER_EXPORT___;
