import { useQueryData } from "@api/hooks";
import { polygonService } from "@api/services";

export const useLocationTypeShareQuery = (config) => {
  const { data, ...restOfQueryParams } = useQueryData(
    ["location-type-shares"],
    async () => await polygonService.getLocationTypeShares(),
    { ...config }
  );

  return {
    locationTypeShare: { ...data },
    ...restOfQueryParams,
  };
};
