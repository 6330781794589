import {
  BoundingSphere,
  Cesium3DTileset,
  HeadingPitchRange,
  Transforms, // eslint-disable-next-line no-unused-vars
  Viewer,
} from "cesium";

import { findPrimitiveById } from "./findByPrimitiveId";

/**
 * Fly the viewer to the specified asset.
 *
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {string} assetId - The ID of the asset to fly to.
 * @param {function} onComplete - A callback function to be executed when the flight is complete.
 */
export const flyToAsset = (viewer, assetId, onComplete) => {
  const assetPrimitive = findPrimitiveById(
    viewer?.scene?.primitives,
    `asset-${assetId}`
  );

  if (!assetPrimitive || !(assetPrimitive instanceof Cesium3DTileset)) {
    console.error("Asset not found or is not a 3D Tileset");
    return;
  }

  const handleEscapeKeyPress = (event) => {
    if (event.keyCode === 27) {
      viewer.camera.cancelFlight();
      onComplete && onComplete();
    }
  };

  window.addEventListener("keydown", handleEscapeKeyPress);

  const boundingSphere = assetPrimitive.boundingSphere;

  const adjustedBoundingSphere = new BoundingSphere(
    boundingSphere.center,
    boundingSphere.radius
  );

  const offset = new HeadingPitchRange(0, -Math.PI / 2, 500);

  const handleMoveEnd = () => {
    viewer.camera.moveEnd.removeEventListener(handleMoveEnd);
  };

  viewer.camera.moveEnd.addEventListener(handleMoveEnd);

  viewer.camera.flyToBoundingSphere(adjustedBoundingSphere, {
    duration: 6,
    offset,
    complete: () => {
      window.removeEventListener("keydown", handleEscapeKeyPress);
      rotateAroundAsset(viewer, adjustedBoundingSphere.center).then(() => {
        onComplete && onComplete();
        viewer.camera.flyToBoundingSphere(adjustedBoundingSphere, {
          duration: 6,
          offset: new HeadingPitchRange(0, -Math.PI / 2, 1000),
        });
      });
    },
  });
};

function rotateAroundAsset(viewer, position) {
  return new Promise((resolve) => {
    let currentRotation = 0;
    let changePitch = false;
    let initialRange;
    let stopRotation = false;

    const handleEscapeKeyPress = (event) => {
      if (event.keyCode === 27) {
        stopRotation = true;
        removeEventListeners();
        setCameraToBirdsEye();
        resolve();
      }
    };

    const setCameraToBirdsEye = () => {
      viewer.camera.lookAtTransform(
        Transforms.eastNorthUpToFixedFrame(position),
        new HeadingPitchRange(viewer.camera.heading, -Math.PI / 2, initialRange)
      );
    };

    const removeEventListeners = () => {
      viewer.scene.postRender.removeEventListener(rotationFunction);
      window.removeEventListener("keydown", handleEscapeKeyPress);
    };

    const rotationFunction = function () {
      if (!initialRange) {
        initialRange = viewer.camera.positionCartographic.height;
      }

      if (!changePitch) {
        viewer.camera.lookAtTransform(
          Transforms.eastNorthUpToFixedFrame(position),
          new HeadingPitchRange(
            viewer.camera.heading,
            -Math.PI / 4,
            initialRange
          )
        );
        changePitch = true;
      }

      if (!stopRotation) {
        const rotationStep = Math.PI / 270;
        const heading = viewer.camera.heading + rotationStep;
        const pitch = viewer.camera.pitch;
        const range = viewer.camera.positionCartographic.height / 1.5;

        viewer.camera.lookAtTransform(
          Transforms.eastNorthUpToFixedFrame(position),
          new HeadingPitchRange(heading, pitch, range)
        );

        currentRotation += rotationStep;
        if (currentRotation >= 2 * Math.PI) {
          stopRotation = true;
          removeEventListeners();
          setCameraToBirdsEye();
          resolve();
        }
      }
    };

    viewer.scene.postRender.addEventListener(rotationFunction);
    window.addEventListener("keydown", handleEscapeKeyPress);
  });
}
