import clsx from "clsx";
import PropTypes from "prop-types";

import Notification from "./Notification";
import styles from "./Notification.module.css";

const Notifications = ({ notifications, onOpen }) => {
  const unreadNotificationsCount =
    notifications?.filter(({ isRead }) => !isRead)?.length || 0;
  return (
    <div className={styles.notificationContent}>
      <h3
        className={clsx(
          styles.notificationsHeading,
          unreadNotificationsCount && styles.unreadNotifications
        )}
      >
        Notifications{" "}
        {unreadNotificationsCount ? `(${unreadNotificationsCount})` : ""}
      </h3>
      <div className={styles.notificationMessages}>
        {notifications?.map(({ id, title, date, isRead }) => (
          <Notification
            key={id}
            title={title}
            date={date}
            isRead={isRead}
            onClick={onOpen}
          />
        ))}
      </div>
      <button className={styles.clearMessages}>Clear all</button>
    </div>
  );
};

Notifications.propTypes = {
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      date: PropTypes.string,
      isRead: PropTypes.bool,
    })
  ),
  onOpen: PropTypes.func,
};

export default Notifications;
