import { Cartesian3, Matrix4 } from "cesium";
import { useCallback, useEffect } from "react";

const useCameraPosition = (viewer, lookAtUserLocation = false) => {
  const setCameraPosition = useCallback(
    (position) => {
      if (!lookAtUserLocation) {
        return;
      }

      let target;
      const offset = new Cartesian3(1000.0, -20000, 15000000);
      if (position) {
        const { latitude, longitude } = position.coords;
        target = Cartesian3.fromDegrees(longitude, latitude, 0);
      } else {
        target = new Cartesian3(
          5738667.946228014,
          2316921.189352922,
          1499033.9616696648
        );
      }

      viewer.camera.lookAt(target, offset);
      viewer.camera.lookAtTransform(Matrix4.IDENTITY);
    },
    [viewer, lookAtUserLocation]
  );

  useEffect(() => {
    if (!viewer) {
      return;
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => setCameraPosition(position),
        () => setCameraPosition(null)
      );
    } else {
      console.warn("Geolocation API not supported by this browser.");
    }
  }, [setCameraPosition, viewer]);

  return setCameraPosition;
};

export { useCameraPosition };
