import axios from "axios";
import { formatUnits, parseEther, parseUnits } from "ethers";

import { formatNumber } from "@shared/helpers";

import { CONTRACT_ERROR_CODES, WALLET_ACTION_ERROR_CODES } from "./constants";

export const removeScientificNotation = (value) => {
  return +value.toLocaleString("fullwide", { useGrouping: false });
};

export const toWei = (value, currencyDecimals = 18) => {
  if (value === undefined || value === "") {
    return;
  }

  return parseUnits(value.toString(), currencyDecimals).toString();
};

export function fromWei(value, currencyDecimals = 18) {
  if (value === undefined) {
    return;
  }

  const rounded = removeScientificNotation(Math.round(+value).toString());
  return +formatUnits(BigInt(rounded), currencyDecimals);
}

export function parseValueToEther(value) {
  return parseEther(value);
}

export const formatDisplayPrice = (value, currencyDecimals, paymentLabel) => {
  const formattedUnit = fromWei(value, currencyDecimals);

  if (!formattedUnit) {
    return;
  }

  return !paymentLabel
    ? formatNumber(formattedUnit)
    : `${formatNumber(formattedUnit)} ${paymentLabel}`;
};

export const hexToNumber = (hex) => parseInt(hex, 16);

export const numberToHex = (number) => "0x" + number.toString(16);

const findErrorCodeInMessage = (message) => {
  const errorCodes = Object.keys(WALLET_ACTION_ERROR_CODES).concat(
    Object.keys(CONTRACT_ERROR_CODES)
  );
  for (const code of errorCodes) {
    if (message.includes(`"code": ${code}`) || message.includes(code)) {
      return code;
    }
  }
};

export const readNetworkError = (error) => {
  const isBadRequest = error?.response?.status === 400;

  if (isBadRequest) {
    return error?.response?.data;
  }
};

export const WalletErrorHandler = (error, disableDefault = false) => {
  if (!error) {
    return "An unknown error occurred.";
  }

  if (error.code && WALLET_ACTION_ERROR_CODES[error.code]?.message) {
    return WALLET_ACTION_ERROR_CODES[error.code]?.message;
  }

  if (error["error"]?.code === -32603) {
    return;
  }

  if (!error.code) {
    const codeFromMessage = findErrorCodeInMessage(error.message);
    if (codeFromMessage) {
      return WALLET_ACTION_ERROR_CODES[codeFromMessage]?.message;
    }
  }

  if (error?.message && error?.message === "User rejected methods.") {
    return "User has rejected the request";
  }

  if (!WALLET_ACTION_ERROR_CODES[error.code]?.message && disableDefault) {
    return;
  }

  const errorMessage =
    WALLET_ACTION_ERROR_CODES[error.code]?.message ||
    (typeof CONTRACT_ERROR_CODES[error.code] === "object"
      ? CONTRACT_ERROR_CODES[error.code][error.reason]
      : CONTRACT_ERROR_CODES[error.code]) ||
    readNetworkError(error) ||
    error.message ||
    "There was an issue processing your transaction";

  return errorMessage;
};

export function generateNonce() {
  return Date.now();
}

const COINGECKO_API_URL = "https://api.coingecko.com/api/v3/simple/price";

export const getMaticToUsdRate = async () => {
  const response = await axios.get(COINGECKO_API_URL, {
    params: {
      ids: "matic-network",
      vs_currencies: "usd",
    },
  });

  return response.data["matic-network"].usd;
};

export const convertMaticToUsd = (maticAmount, rate) => {
  if (!rate) {
    return 0;
  }
  return maticAmount * rate;
};
export const convertUsdToMatic = (usdAmount, rate) => {
  if (!rate) {
    return 0;
  }
  return usdAmount / rate;
};
