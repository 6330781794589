import * as turf from "@turf/turf";
import {
  BoundingSphere,
  Cartographic,
  HeadingPitchRange,
  Cartesian3 as cesiumCartesian3,
  sampleTerrain,
} from "cesium";
import { toast } from "react-toastify";

/**
 * Generates a function comment for the given function body.
 *
 * @param {Viewer} viewer - The Cesium viewer.
 * @param {Array} polygonCoordinates - The array of polygon coordinates.
 * @param {Function} [onComplete] - The callback function to be executed when the flight is complete.
 * @return {Promise} A promise that resolves when the function completes.
 */
const showPolygonAtCoordinates = async (viewer, polygons, lat, lon) => {
  if (!viewer || !polygons || !polygons.length) {
    console.warn("Viewer or polygons data is not available.");
    return;
  }

  const point = turf.point([lon, lat]);
  let foundPolygon = null;

  for (const polygon of polygons) {
    const vertices = polygon.vertices.map(([lat, lon]) => [lon, lat]);
    const polygonGeoJson = turf.polygon([vertices]);

    if (turf.booleanPointInPolygon(point, polygonGeoJson)) {
      foundPolygon = polygon;
      break;
    }
  }

  if (foundPolygon) {
    const cartographicPoints = foundPolygon.vertices.map(([lat, lon]) =>
      Cartographic.fromDegrees(lon, lat)
    );

    const terrainProvider = viewer?.terrainProvider;
    if (!terrainProvider) {
      console.warn("Terrain provider is not available.");
      return;
    }

    const raisedPositions = await sampleTerrain(
      terrainProvider,
      11,
      cartographicPoints
    );

    const points = raisedPositions.map(({ longitude, latitude, height }) =>
      cesiumCartesian3.fromRadians(longitude, latitude, height)
    );

    const boundingSphere = BoundingSphere.fromPoints(points);
    const adjustedBoundingSphere = new BoundingSphere(
      boundingSphere.center,
      boundingSphere.radius
    );
    const offset = new HeadingPitchRange(0, -Math.PI / 2);

    viewer.camera.flyToBoundingSphere(adjustedBoundingSphere, {
      offset: offset,
      duration: 0,
    });

    return true;
  } else {
    toast.info("No polygon found for the given coordinates.");
    return false;
  }
};

export { showPolygonAtCoordinates };
