import { useQueryData } from "@api/hooks";
import { nftService } from "@api/services";

const useOwnedNftsQuery = (userId) => {
  const { data, ...restOfQueryParams } = useQueryData(
    ["owned-nfts"],
    async () => await nftService.getOwnedNfts(userId),
    { enabled: !!userId }
  );

  return {
    nfts: data?.nfts,
    ownedPolygonsCount: data?.ownedPolygonsCount || 0,
    ownedAssetsCount: data?.ownedAssetsCount || 0,
    ...restOfQueryParams,
  };
};

export { useOwnedNftsQuery };
