import fetchData from "@api/axios";

const authService = {
  async createJwt(data) {
    return await fetchData({
      routeKey: "createJwt",
      method: "POST",
      data,
    });
  },

  async getJwt(identifier) {
    return await fetchData({
      routeKey: "getJwt",
      params: identifier,
    });
  },
  async deleteJwt(identifier) {
    return await fetchData({
      routeKey: "deleteJwt",
      method: "DELETE",
      params: identifier,
    });
  },
  async sendVerificationCode(email) {
    return await fetchData({
      routeKey: "sendVerificationCode",
      method: "POST",
      data: { email },
    });
  },

  async verifyCode(email, code) {
    return await fetchData({
      routeKey: "verifyCode",
      method: "POST",
      data: { email, code },
    });
  },
  async checkUsername(id, username) {
    return await fetchData({
      routeKey: "checkUsername",
      method: "POST",
      data: { id, username },
    });
  },
  async checkEmail(id, email) {
    return await fetchData({
      routeKey: "checkEmail",
      method: "POST",
      data: { id, email },
    });
  },
};

export { authService };
