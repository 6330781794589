import fetchData from "@api/axios";

const presaleService = {
  async checkPresaleCode({ data }) {
    return await fetchData({
      routeKey: "checkPresaleCode",
      method: "POST",
      data,
    });
  },
  async invalidatePresaleCode({ data }) {
    return await fetchData({
      routeKey: "invalidatePresaleCode",
      method: "POST",
      data,
    });
  },
};

export { presaleService };
