import fetchData from "@api/axios";

const userService = {
  async getUsersByIds(identifiers) {
    return await fetchData({
      routeKey: "getUsersByIds",
      params: Array.isArray(identifiers) ? identifiers.join(",") : identifiers,
    });
  },
  async getUser(identifier) {
    return await fetchData({
      routeKey: "getUser",
      params: identifier,
    });
  },

  async createUser(data) {
    return await fetchData({
      routeKey: "createUser",
      method: "POST",
      data,
    });
  },
  async updateUser(data) {
    return await fetchData({
      routeKey: "updateUser",
      method: "PUT",
      data,
      params: data.walletAddress,
    });
  },
};

export { userService };
