import {
  Cartesian3,
  Cesium3DTileset,
  ClassificationType,
  HeadingPitchRoll,
  IonResource,
  Matrix4,
  Transforms,
  Math as cesiumMath,
} from "cesium";
import { useCallback } from "react";

import { findPrimitiveById } from "@features/Cesium/utils/findByPrimitiveId";

const useAssets = (viewer, showAssets) => {
  const loadDatabaseAssetsInViewer = useCallback(
    async (assets, savedPolygons) => {
      if (!viewer?.scene || !savedPolygons) {
        return;
      }

      for (const asset of assets) {
        const existingAsset = findPrimitiveById(
          viewer?.scene?.primitives,
          `asset-${asset.id}`
        );
        if (existingAsset) {
          continue;
        }

        const matchingPolygon = savedPolygons.find(
          (polygon) => polygon.id === asset.polygonId
        );

        if (matchingPolygon && matchingPolygon.centroidCoordinates) {
          const adjustedPosition = Cartesian3.fromDegrees(
            asset.position[1],
            asset.position[0],
            asset.height
          );

          const heading = cesiumMath.toRadians(asset.rotation);
          const pitch = cesiumMath.toRadians(0);
          const roll = cesiumMath.toRadians(0);

          const hpr = new HeadingPitchRoll(heading, pitch, roll);
          const orientation = Transforms.headingPitchRollQuaternion(
            adjustedPosition,
            hpr
          );

          const modelMatrix = Matrix4.fromTranslationQuaternionRotationScale(
            adjustedPosition,
            orientation,
            new Cartesian3(1, 1, 1)
          );

          try {
            const resource = await IonResource.fromAssetId(asset.assetTileId);
            const tileset = await Cesium3DTileset.fromUrl(resource, {
              skipLevelOfDetail: true,
              baseScreenSpaceError: 1024,
              skipScreenSpaceErrorFactor: 16,
              skipLevels: 1,
              immediatelyLoadDesiredLevelOfDetail: false,
              loadSiblings: false,
              cullWithChildrenBounds: true,
              modelMatrix,
              dynamicScreenSpaceError: true,
              dynamicScreenSpaceErrorDensity: 0.00278,
              dynamicScreenSpaceErrorFactor: 4.0,
              dynamicScreenSpaceErrorHeightFalloff: 0.25,
              classificationType: ClassificationType.CESIUM_3D_TILE_TILING,
              show: showAssets,
            });
            tileset.id = `asset-${asset.id}`;
            tileset.ownerId = asset.ownerId;
            viewer.scene.primitives.add(tileset);
            viewer.scene.requestRender();
          } catch (error) {
            console.error(`Error creating tileset: ${error}`);
          }
        }
      }
    },
    [showAssets, viewer?.scene]
  );

  const toggleAssetsVisibility = useCallback(
    (showAssets) => {
      if (viewer?.scene && viewer?.scene?.primitives) {
        const primitives = viewer.scene.primitives;
        for (let i = 0; i < primitives.length; i++) {
          const primitive = primitives.get(i);
          if (primitive.id && primitive.id.startsWith("asset-")) {
            primitive.show = showAssets;
          }
        }
        viewer.scene.requestRender();
      }
    },
    [viewer?.scene]
  );

  const deleteAssetFromViewer = useCallback(
    (assetId) => {
      const assetToDelete = findPrimitiveById(
        viewer?.scene?.primitives,
        `asset-${assetId}`
      );

      if (assetToDelete) {
        viewer.scene.primitives.remove(assetToDelete);
        viewer.scene.requestRender();
      }
    },
    [viewer?.scene]
  );

  return {
    loadDatabaseAssetsInViewer,
    toggleAssetsVisibility,
    deleteAssetFromViewer,
  };
};

export { useAssets };
