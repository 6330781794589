import { Buffer } from "buffer";
import { createRoot } from "react-dom/client";
import "react-toastify/dist/ReactToastify.css";

import "@styles/styles.css";

import App from "./app";

window.Buffer = window.Buffer || Buffer;

const root = createRoot(document.getElementById("root"));
root.render(<App />);
