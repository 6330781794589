// eslint-disable-next-line no-unused-vars
import { Viewer, Color as cesiumColor } from "cesium";
import { toast } from "react-toastify";

/**
 * Updates the color and alpha transparency of a polygon in the Cesium viewer.
 *
 * @param {Viewer} viewer - The Cesium viewer object.
 * @param {string} polygonId - The ID of the polygon.
 * @param {string} color - The CSS color string representing the new color.
 * @param {number} alpha - The alpha transparency value between 0 and 1.
 * @return {void}
 */
export const changePolygonColorAlpha = (viewer, polygonId, color, alpha) => {
  if (!viewer?.entities) {
    return;
  }
  const polygonEntity = viewer.entities.getById(`polygon-${polygonId}`);
  if (polygonEntity) {
    polygonEntity.polygon.material = cesiumColor
      .fromCssColorString(color)
      .withAlpha(alpha);
  } else {
    toast.error(`Polygon with id ${polygonId} not found`);
  }
};
