import config from "@config/index";
import { BrowserProvider } from "ethers";
import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useReducer } from "react";
import { toast } from "react-toastify";

import { queryClient } from "@api/queryClient";
import { authService } from "@api/services";

import {
  WALLET_CONNECT,
  Web3QueryKeys,
  Web3WalletEvent,
  providerEvents,
} from "@Web3/constants";
import { WalletErrorHandler } from "@Web3/utils";

import { getMessageToSign } from "@app-shared/index";

import {
  cacheConnector,
  clearCachedConnector,
  getCachedConnector,
} from "../connectors/connectorCache";
import { ACTIONS } from "../store/actions";
import web3ContextReducer from "../store/reducers";
import { initialState } from "../store/state";
import { WalletActionsContext, WalletStateContext } from "./WalletContext";

const WalletContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(web3ContextReducer, initialState);

  const clearState = () => {
    dispatch({ type: ACTIONS.DISCONNECTED });
    dispatch({
      type: ACTIONS.UPDATE_WEB3_WALLET_EVENT,
      payload: Web3WalletEvent.DISCONNECT_SUCCESS,
    });
    clearCachedConnector();
    queryClient.removeQueries();
  };

  const connectWallet = useCallback(async (connector, account) => {
    dispatch({
      type: ACTIONS.SET_CONNECTING,
      payload: { isConnecting: true },
    });

    try {
      const provider = await connector.connect();

      if (provider) {
        const web3 = new BrowserProvider(provider, "any");
        const signer = account
          ? await web3.getSigner(account)
          : await web3.getSigner();
        const currentAddress = account || (await signer.getAddress());
        const chainId = (await web3.getNetwork()).chainId.toString();

        if (!currentAddress) {
          clearState();
          return;
        }

        const { data: token } = await authService.getJwt(
          `session_token_${currentAddress}`
        );

        if (!token) {
          const signAndCreateJwt = async () => {
            try {
              if (connector.id === WALLET_CONNECT) {
                toast.info(
                  "Please sign message in your wallet when popup appears!"
                );
              }

              const signedMessage = await signer.signMessage(
                getMessageToSign(currentAddress)
              );

              await authService.createJwt({ currentAddress, signedMessage });
              toast.success("You are successfully connected to the app!", {
                toastId: "connect-wallet-success",
              });
            } catch (error) {
              const errorMessage = WalletErrorHandler(error);
              throw new Error(errorMessage);
            }
          };

          await signAndCreateJwt();
        }

        return {
          web3,
          ethereumProvider: provider,
          chainId,
          currentAddress,
          signer,
        };
      }
    } catch (error) {
      const errorMessage = WalletErrorHandler(error);
      clearState();
      await authService.deleteJwt(`session_token`);
      toast.error(errorMessage);
    }
  }, []);

  const disconnect = useCallback(async () => {
    if (state.ethereumProvider?.close) {
      await state.ethereumProvider.close();
    } else if (state.ethereumProvider?.disconnect) {
      await state.ethereumProvider.disconnect();
    }
    clearState();
    await authService.deleteJwt(`session_token_${state.currentAddress}`);
  }, [state.ethereumProvider, state.currentAddress]);

  const addChain = useCallback(
    async (chainData) => {
      if (state.ethereumProvider) {
        await state.ethereumProvider.request({
          method: "wallet_addEthereumChain",
          params: [chainData],
        });
      }
    },
    [state.ethereumProvider]
  );

  const switchChain = useCallback(
    async (chainId, chainName, onSuccessFn) => {
      try {
        if (state.ethereumProvider) {
          await state.ethereumProvider.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId }],
          });
        }
        onSuccessFn();
      } catch (switchError) {
        if (switchError.code === 4902 || switchError.code === 5000) {
          await addChain({
            chainId,
            chainName,
            nativeCurrency: {
              name: config.blockchain.nativeTokenName,
              symbol: config.blockchain.nativeTokenSymbol,
              decimals: parseInt(config.blockchain.tokenDecimals),
            },
            rpcUrls: [config.blockchain.rpcUrl],
            blockExplorerUrls: [config.blockchain.blockExplorerUrl],
          });
        }

        if (switchError.code === 4001) {
          toast.error("User rejected the request");
        }

        if (switchError.code === 5201) {
          toast.error(
            `Switch to or add ${chainName} in your wallet than try again`
          );
        }
      }
    },
    [state.ethereumProvider, addChain]
  );

  const connectToEthereum = useCallback(
    async (connector, account) => {
      try {
        if (!connector) {
          return;
        }
        const cachedConnector = getCachedConnector();

        dispatch({
          type: ACTIONS.UPDATE_WEB3_WALLET_EVENT,
          payload: cachedConnector
            ? Web3WalletEvent.WALLET_RECONNECTED
            : Web3WalletEvent.CONNECT_SUCCESS,
        });

        const data = await connectWallet(connector, account);

        if (data) {
          const { web3, chainId, signer, currentAddress, ethereumProvider } =
            data;

          cacheConnector(connector);
          dispatch({
            type: ACTIONS.CONNECTED,
            payload: {
              web3,
              ethereumProvider,
              chainId: +chainId,
              currentAddress,
              signer,
            },
          });
        }
      } finally {
        await Promise.all([
          queryClient.invalidateQueries(["users"]),
          queryClient.invalidateQueries([Web3QueryKeys.NATIVE_BALANCE]),
          queryClient.invalidateQueries([Web3QueryKeys.ERC20_BALANCE]),
        ]);
        dispatch({
          type: ACTIONS.SET_CONNECTING,
          payload: { isConnecting: false },
        });
      }
    },
    [connectWallet]
  );

  const updateWeb3WalletEvents = useCallback((event) => {
    dispatch({ type: ACTIONS.UPDATE_WEB3_WALLET_EVENT, payload: event });
  }, []);

  const subscribeToEthereumProviderEvents = useCallback(
    (provider) => {
      if (!provider.on) {
        return;
      }

      const onChainChanged = (chainId) => {
        dispatch({ type: ACTIONS.CHAIN_CHANGED, payload: { chainId } });
      };

      const onDisconnect = async (code) => {
        switch (code?.code || code) {
          case 1013: {
            return;
          }
          case 1011: {
            window.location.reload();
            return;
          }
          default: {
            clearState();
            await authService.deleteJwt(
              `session_token_${state.currentAddress}`
            );
            queryClient.removeQueries(["users", state.currentAddress]);
            dispatch({
              type: ACTIONS.UPDATE_WEB3_WALLET_EVENT,
              payload: Web3WalletEvent.DISCONNECT_SUCCESS,
            });
          }
        }
      };

      const onAccountChanged = async (accounts) => {
        if (accounts.length === 0) {
          await disconnect();

          return;
        }

        if (
          accounts[0]?.toLowerCase() === state?.currentAddress.toLowerCase()
        ) {
          return;
        }
        queryClient.removeQueries();
        const connector = getCachedConnector();

        try {
          await connectToEthereum(
            connector,
            connector?.id === WALLET_CONNECT ? accounts[0] : null
          );
        } catch (error) {
          const errorMessage = WalletErrorHandler(error);

          throw new Error(errorMessage);
        }

        dispatch({
          type: ACTIONS.UPDATE_WEB3_WALLET_EVENT,
          payload: Web3WalletEvent.WALLET_RECONNECTED,
        });
      };

      provider.on(providerEvents.CHAIN_CHANGED, onChainChanged);
      provider.on(providerEvents.ACCOUNTS_CHANGED, onAccountChanged);
      provider.on(providerEvents.DISCONNECT, onDisconnect);

      return () => {
        provider.removeListener(providerEvents.CHAIN_CHANGED, onChainChanged);
        provider.removeListener(
          providerEvents.ACCOUNTS_CHANGED,
          onAccountChanged
        );
        provider.removeListener(providerEvents.DISCONNECT, onDisconnect);
      };
    },
    [connectToEthereum, disconnect, state.currentAddress]
  );

  const toggleWalletConnectModal = useCallback(() => {
    dispatch({ type: ACTIONS.WALLET_PICKER_TOGGLE });
  }, []);

  const actions = useMemo(
    () => ({
      connectToEthereum,
      disconnect,
      addChain,
      switchChain,
      toggleWalletConnectModal,
      updateWeb3WalletEvents,
    }),
    [
      connectToEthereum,
      disconnect,
      addChain,
      switchChain,
      toggleWalletConnectModal,
      updateWeb3WalletEvents,
    ]
  );

  useEffect(() => {
    if (state.ethereumProvider && (state.currentAddress || state.chainId)) {
      const providerEvents = subscribeToEthereumProviderEvents(
        state.ethereumProvider
      );
      return providerEvents;
    }
  }, [
    state.chainId,
    state.currentAddress,
    state.ethereumProvider,
    subscribeToEthereumProviderEvents,
  ]);

  useEffect(() => {
    const cachedConnector = getCachedConnector();
    if (cachedConnector) {
      connectToEthereum(cachedConnector);
    }
  }, [connectToEthereum]);

  return (
    <WalletStateContext.Provider value={state}>
      <WalletActionsContext.Provider value={actions}>
        {children}
      </WalletActionsContext.Provider>
    </WalletStateContext.Provider>
  );
};

WalletContextProvider.propTypes = {
  children: PropTypes.node,
};

export default WalletContextProvider;
