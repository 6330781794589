import { Ion, buildModuleUrl } from "cesium";
import { Suspense, useState } from "react";
import { Slide, ToastContainer } from "react-toastify";

import CesiumApp from "@features/Cesium/CesiumApp";
import ViewerContainer from "@features/Cesium/ViewerContainer";
import NavigationSidebar from "@features/NavigationSidebar";
import Whitelist from "@features/Whitelist";

import { Loader } from "@components/Loader";

import storage from "@services/storage";

import config from "./config";
import Providers from "./providers";

Ion.defaultAccessToken = config.app.cesiumApiKey;
buildModuleUrl.setBaseUrl("/cesium/");

const App = () => {
  const [showWhitelist, setShowWhitelist] = useState(
    () => !storage.get("whitelist")
  );
  return (
    <>
      {showWhitelist ? (
        <Whitelist onValidAddress={() => setShowWhitelist(false)} />
      ) : (
        <Providers>
          <ToastContainer
            position="top-right"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            transition={Slide}
          />

          <ViewerContainer />
          <Suspense fallback={<Loader />}>
            <CesiumApp />
          </Suspense>
          <NavigationSidebar />
        </Providers>
      )}
    </>
  );
};

export default App;
