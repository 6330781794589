const base64ToBlob = (base64) => {
  const [base64Header, base64String] = base64.split(",");
  const mimeType = base64Header.match(/data:([^;]+);/)[1];

  const binaryString = Buffer.from(base64String, "base64").toString("binary");
  const byteArrays = [];

  for (let offset = 0; offset < binaryString.length; offset += 512) {
    const slice = binaryString.slice(offset, offset + 512);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
};

export { base64ToBlob };
