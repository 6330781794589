import { useQueryData } from "@api/hooks";

import { nftService } from "../services";

const useNft = (nftId) => {
  return useQueryData(
    ["nfts", nftId],
    async () => await nftService.getNftById(nftId),
    { enabled: !!nftId }
  );
};

export { useNft };
