// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SDgtC92z {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 12px;
}

.sfRd_LJV {
  position: absolute;
  width: 80px;
  height: 61px;
  left: 34px;
  top: 88px;
  border-radius: 8px;
  background-color: #000;
}

.sfRd_LJV > img {
  margin: 0 auto;
  border-radius: 8px;
  object-fit: cover;
}

.TAYCsLjj {
  max-width: 200px;
  width: 100%;
  height: 145px;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1),
    0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
  border-radius: 8px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: var(--land-image);
}

.CzLu8E_v {
  align-self: flex-start;
  margin-top: 0.5rem;
}

.Ekalh8qY {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #fff;
  margin: 0;
  padding-left: 0.6rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.Ekalh8qY:hover {
  transform: scale(1.05);
}

.xQ64GPFj {
  opacity: 0.5;
}

.sfRd_LJV {
  opacity: 1;
  filter: none;
  z-index: 1;
}

.Nwmzz0Lj {
  opacity: 0.6;
  filter: brightness(0.7);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"landBlock": `SDgtC92z`,
	"assetSquare": `sfRd_LJV`,
	"landBlockImage": `TAYCsLjj`,
	"landBlockInfoWrapper": `CzLu8E_v`,
	"landBlockInfo": `Ekalh8qY`,
	"textOverlay": `xQ64GPFj`,
	"imageOverlay": `Nwmzz0Lj`
};
export default ___CSS_LOADER_EXPORT___;
