export const calculateMaxModelSize = (area) => {
  const sizeTable = [
    { maxArea: 1000, maxSize: 0 },
    { maxArea: 5000, maxSize: 50 },
    { maxArea: 50000, maxSize: 70 },
    { maxArea: 70000, maxSize: 90 },
    { maxArea: 1000000, maxSize: 100 },
  ];

  const mbToBytes = 1024 * 1024;
  const matchingSize =
    sizeTable.find((entry) => area < entry.maxArea) ||
    sizeTable[sizeTable.length - 1];

  return {
    maxSizeBytes: matchingSize.maxSize * mbToBytes,
    maxSizeMB: matchingSize.maxSize,
  };
};
