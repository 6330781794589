import { useState } from "react";

import { clsx } from "@shared/helpers";
import { useOutsideClick } from "@shared/hooks";

import Dismiss from "@icons/Dismiss.svg";
import BigStar from "@icons/big-star.svg";
import decentralverseLogo from "@icons/dcentralverse-hexagon.svg";
import MediumStar from "@icons/medium-star.svg";
import MoonIcon from "@icons/planet-icons.svg";
import PlanetIcon from "@icons/planet.svg";
import SmallStar from "@icons/small-star.svg";

import styles from "./SwitchPlanet.module.css";

const SwitchPlanet = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const ref = useOutsideClick({
    callback: () => setIsModalOpen(false),
    enabled: true,
  });

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <>
      <div className={styles.container}>
        <img
          className={styles.logo}
          src={decentralverseLogo}
          alt="Decentralverse logo"
        />
        <button className={styles.planetIcon} onClick={toggleModal}>
          <img
            height="41.14px"
            width="41.14px"
            src={PlanetIcon}
            alt="Planet icon"
          />
        </button>
      </div>
      {isModalOpen && (
        <div ref={ref} className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.modalTitle}>Switch Planet</h5>
            <button onClick={toggleModal} className={styles.closeButton}>
              <img src={Dismiss} alt="Dismiss" />
            </button>
          </div>
          <button className={styles.modalContent} onClick={toggleModal}>
            <img
              width={28}
              height={28}
              src={PlanetIcon}
              alt="Earth"
              className={styles.modalPlanetIcon}
            />
            <span className={styles.button}>Earth</span>
          </button>
          <a
            href="https://docs.decentralverse.io/decentralverse/ai-generated-planets/create-your-own-planet"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.modalContent}
          >
            <div className={styles.aiGeneratedPlanetsIcons}>
              <img
                width={28}
                height={28}
                src={MoonIcon}
                alt="AI generated planets"
                className={styles.modalPlanetIcon}
              />
              <img
                src={BigStar}
                alt="Star"
                className={clsx(styles.star, styles.big)}
              />
              <img
                src={MediumStar}
                alt="Star"
                className={clsx(styles.star, styles.medium)}
              />
              <img
                src={SmallStar}
                alt="Star"
                className={clsx(styles.star, styles.small)}
              />
            </div>
            <div className={styles.planets}>
              <span>AI generated planets</span>
              <span className={styles.comingSoon}>Coming soon</span>
            </div>
          </a>
        </div>
      )}
    </>
  );
};

export default SwitchPlanet;
