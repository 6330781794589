import fetchData from "@api/axios";

import { base64ToBlob } from "@shared/helpers";

const fileService = {
  async uploadFile({ file, category, name, assetId }) {
    const formData = new FormData();

    let blob;
    let fileName;

    if (
      typeof file === "string" &&
      (file.startsWith("data:image") ||
        file.startsWith("data:model/gltf-binary"))
    ) {
      blob = base64ToBlob(file);
      const mimeType = file.match(/data:([^;]+);/)[1];
      fileName = `${file.startsWith("data:image") ? "image" : "model"}.${
        mimeType.split("/")[1]
      }`;
    } else {
      blob = file;
      fileName = file.name;
    }

    formData.append("file", blob, fileName);
    formData.append("category", category);
    name && formData.append("name", name);
    assetId && formData.append("assetId", assetId);

    return await fetchData({
      routeKey: "uploadFile",
      method: "POST",
      data: formData,
      isFormData: true,
    });
  },
  async getAssetTillingStatus(assetId) {
    return await fetchData({
      routeKey: "getAssetTillingStatus",
      params: assetId,
    });
  },
};

export { fileService };
