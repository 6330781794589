import PropTypes from "prop-types";

import styles from "./Loader.module.css";

const Loader = ({ size = "sm", backgroundColor }) => {
  let loaderSize, borderSize;

  switch (size) {
    case "xl":
      loaderSize = 180;
      borderSize = 24;
      break;
    case "lg":
      loaderSize = 120;
      borderSize = 16;
      break;
    case "md":
      loaderSize = 90;
      borderSize = 12;
      break;
    case "sm":
      loaderSize = 60;
      borderSize = 8;
      break;
    case "xs":
      loaderSize = 24;
      borderSize = 4;
      break;
    case "xxs":
      loaderSize = 20;
      borderSize = 2;
      break;
    default:
      loaderSize = 60;
      borderSize = 8;
  }

  return (
    <div
      className={styles.loader}
      style={{
        borderWidth: borderSize,
        width: loaderSize,
        height: loaderSize,
        backgroundColor: backgroundColor || "transparent",
      }}
    />
  );
};

Loader.propTypes = {
  size: PropTypes.oneOf(["xl", "lg", "md", "sm", "xs", "xxs"]),
  backgroundColor: PropTypes.string,
};

export default Loader;
