import PropTypes from "prop-types";
import { createContext, useContext, useEffect, useState } from "react";

import { useCesiumApp } from "@features/Cesium/context/CesiumProvider";

import storage from "@services/storage";

import { units } from "../constants/units";

const SettingsContext = createContext();

export const useSettings = () => {
  return useContext(SettingsContext);
};

const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState({
    measurementUnit: units.METERS,
    showLands: true,
    showAssets: true,
  });

  const { setShowEntities } = useCesiumApp();

  useEffect(() => {
    const storedSettings = storage.get("settings");
    if (storedSettings) {
      setSettings(storedSettings);
    }
  }, []);

  useEffect(() => {
    storage.save("settings", settings);
  }, [settings]);

  const updateSettings = (key, value) => {
    if (key === "showLands") {
      setShowEntities((prevState) => ({ ...prevState, polygons: value }));
    }
    if (key === "showAssets") {
      setShowEntities((prevState) => ({ ...prevState, assets: value }));
    }
    setSettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };

  return (
    <SettingsContext.Provider value={{ settings, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node,
};

export default SettingsProvider;
