import { keccak256 as ethersKeccak256, parseEther, toUtf8Bytes } from "ethers";
import { useCallback } from "react";

import { useWalletState } from "@Web3/context";
import { WalletErrorHandler } from "@Web3/utils";

const keccak256 = (value) => ethersKeccak256(toUtf8Bytes(value));
const useSignOfferData = ({ collectionAddress }) => {
  const { signer, chainId } = useWalletState();

  const signOfferData = useCallback(
    async ({ verifierContract, tokenId, price, nonce, expiresAt }) => {
      if (!signer) {
        throw new Error("Wallet not connected");
      }

      if (!collectionAddress) {
        throw new Error("NFT contract not connected");
      }

      const signData = {
        domain: {
          name: "Dcentralverse Exchange",
          version: "1",
          chainId,
          verifyingContract: verifierContract,
          salt: keccak256("Dcentralverse Exchange Salt"),
        },
        types: {
          Offer: [
            { name: "orderNonce", type: "uint256" },
            { name: "nftContract", type: "address" },
            { name: "tokenId", type: "uint256" },
            { name: "price", type: "uint256" },
            { name: "expiresAt", type: "uint256" },
          ],
        },
        value: {
          orderNonce: nonce,
          nftContract: collectionAddress,
          tokenId: tokenId,
          price: parseEther(price),
          expiresAt,
        },
      };

      try {
        const signature = await signer.signTypedData(
          signData.domain,
          signData.types,
          signData.value
        );

        return { signature };
      } catch (error) {
        const errorMessage = WalletErrorHandler(error);

        throw new Error(errorMessage);
      }
    },
    [signer, collectionAddress, chainId]
  );

  return { signOfferData };
};

export default useSignOfferData;
