import { useCallback, useState } from "react";

const useClipboard = () => {
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = useCallback(async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopySuccess(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
      setCopySuccess(false);
    }
  }, []);

  return { copySuccess, copyToClipboard };
};

export { useClipboard };
