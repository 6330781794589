// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KSZwAUwk {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.P5nqUxCG {
  color: #7d7c7c;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
}

._S3cCVZt {
  color: #7d7c7c;
  font-family: "Inter", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
  text-wrap: nowrap;
}

@media screen and (max-width: 768px) {
  ._S3cCVZt {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 36px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"countdownWrapper": `KSZwAUwk`,
	"text": `P5nqUxCG`,
	"counter": `_S3cCVZt`
};
export default ___CSS_LOADER_EXPORT___;
