const validateKey = (key) => {
  if (!key || typeof key !== "string") {
    throw new Error(`Invalid key provided: ${key}`);
  }
};

const storage = {
  save: (key, data) => {
    validateKey(key);
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      throw new Error(
        `Failed to save data for key "${key}" to localStorage: ${error.message}`
      );
    }
  },

  get: (key) => {
    validateKey(key);
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(
        `Failed to retrieve data for key "${key}" from localStorage: ${error.message}`
      );
      return null;
    }
  },

  remove: (key) => {
    validateKey(key);
    try {
      localStorage.removeItem(key);
    } catch (error) {
      throw new Error(
        `Failed to remove key "${key}" from localStorage: ${error.message}`
      );
    }
  },
};

export default storage;
