import config from "@config";

import { Web3WalletEvent } from "@Web3/constants";

import { ACTIONS } from "./actions";
import { initialState } from "./state";

const web3ContextReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.CONNECTED: {
      return {
        ...state,
        web3: action.payload?.web3,
        ethereumProvider: action.payload?.ethereumProvider,
        signer: action.payload?.signer,
        currentAddress: action.payload?.currentAddress,
        chainId: action.payload?.chainId,
        isCorrectChain:
          +[action.payload?.chainId] === +config.blockchain.chainId,
        isConnecting: false,
        isConnectWalletModalOpen: false,
      };
    }
    case ACTIONS.CHAIN_CHANGED:
      return {
        ...state,
        chainId: action.payload?.chainId,
        isCorrectChain:
          +[action.payload?.chainId] === +config.blockchain.chainId,
        isConnecting: false,
      };
    case ACTIONS.DISCONNECTED:
      return {
        ...initialState,
        isConnecting: false,
        isConnectWalletModalOpen: state.isConnectWalletModalOpen,
      };
    case ACTIONS.WALLET_PICKER_TOGGLE:
      return {
        ...state,
        isConnectWalletModalOpen: !state.isConnectWalletModalOpen,
        web3WalletEvent: !state.isConnectWalletModalOpen
          ? Web3WalletEvent.WALLET_MODAL_OPEN
          : Web3WalletEvent.WALLET_MODAL_CLOSE,
      };
    case ACTIONS.SET_CONNECTING:
      return { ...state, isConnecting: action.payload?.isConnecting };
    case ACTIONS.UPDATE_WEB3_WALLET_EVENT:
      return { ...state, web3WalletEvent: action.payload };
  }
};

export default web3ContextReducer;
