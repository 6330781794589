import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PropTypes from "prop-types";
import { lazy } from "react";

import { queryClient } from "@api/queryClient";

import CesiumContextProvider from "@features/Cesium/context/CesiumProvider";
import SettingsProvider from "@features/Settings/context/SettingsContext";

import WalletContextProvider from "@Web3/context/WalletContextProvider";

const ConnectWalletModal = lazy(() => import("@Web3/Wallet"));
const InteractionTooltip = lazy(() => import("@components/InteractionTooltip"));

const Providers = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <CesiumContextProvider>
        <WalletContextProvider>
          <ConnectWalletModal />
          <InteractionTooltip />
          <SettingsProvider>{children}</SettingsProvider>
        </WalletContextProvider>
      </CesiumContextProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

Providers.propTypes = {
  children: PropTypes.node,
};

export default Providers;
