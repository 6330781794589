import { useQueryData } from "@api/hooks";
import { assetService } from "@api/services";

const useAssetTypesQuery = (config) => {
  const { data, ...restOfQueryParams } = useQueryData(
    ["assetTypes"],
    async () => await assetService.getAssetTypes(),
    { ...config }
  );

  return {
    assetTypes: data,
    ...restOfQueryParams,
  };
};

export { useAssetTypesQuery };
