// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ERKhC0oh {
  padding-left: 1rem;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  line-height: 1.5rem;
}
.ERKhC0oh > div {
  margin-bottom: 2rem;
}

.IRuCzCXA p {
  color: #fff;
  width: 75%;
  display: inline-block;
}
.ERKhC0oh .IRuCzCXA:first-of-type p {
  width: 100%;
}

.O0kr3E0K {
  display: inline-block;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.O0kr3E0K .ogXdQjLR {
  color: #ff0691;
  font-weight: 600;
}

.O0kr3E0K .jR7dJKkg {
  color: #fff;
}

.O0kr3E0K i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  border: 2px solid #797e86;
  width: 34px;
  height: 17px;
  background-color: transparent;
  border-radius: 23px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}
.O0kr3E0K i::before {
  content: "";
  position: absolute;
  left: -2px;
  top: -4px;
  width: 30px;
  height: 15px;
  background-color: transparent;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}
.O0kr3E0K i::after {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  width: 11px;
  height: 11px;
  background-color: #797e86;
  border-radius: 11px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}

.O0kr3E0K input:checked ~ .jR7dJKkg {
  color: #ff0691;
  font-weight: 600;
}

.O0kr3E0K input:checked ~ .ogXdQjLR {
  color: #fff;
  font-weight: 400;
}

.O0kr3E0K:active i::after {
  width: 15px;
  transform: translate3d(2px, 2px, 0);
}
.O0kr3E0K:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}
.O0kr3E0K input {
  display: none;
}
.O0kr3E0K input:checked + i {
  background-color: #ff0691;
  border: 0;
}
.O0kr3E0K input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.O0kr3E0K input:checked + i::after {
  background-color: #000;
  transform: translate3d(21px, 4px, 0);
}

.LT1UGXpc {
  position: relative;
}

.LT1UGXpc .ogXdQjLR {
  position: absolute;
  left: 0;
  font-size: 16px;
}

.LT1UGXpc i {
  margin-left: 64px;
  border-color: #fff;
}

.LT1UGXpc i::after {
  background-color: #fff;
}

.LT1UGXpc input:checked + i {
  background-color: transparent;
  border: 2px solid #fff;
}

.LT1UGXpc :checked + i::after {
  background-color: #fff !important;
  transform: translate3d(17px, 2px, 0) !important;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settingsContent": `ERKhC0oh`,
	"settingWrapper": `IRuCzCXA`,
	"formSwitch": `O0kr3E0K`,
	"leftOption": `ogXdQjLR`,
	"rightOption": `jR7dJKkg`,
	"twoOptions": `LT1UGXpc`
};
export default ___CSS_LOADER_EXPORT___;
