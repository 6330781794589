import {
  area as turfArea,
  lineString as turfLineString,
  polygon as turfPolygon,
} from "@turf/turf";
import {
  HeightReference,
  ScreenSpaceEventType,
  CallbackProperty as cesiumCallbackProperty,
  Cartographic as cesiumCartographic,
  Color as cesiumColor,
  defined as cesiumDefined,
  Entity as cesiumEntity,
  Math as cesiumMath,
  PolygonHierarchy as cesiumPolygonHierarchy,
} from "cesium";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import {
  flyToLand,
  getPolygonCoordinates,
  isLineIntersectingExistingPolygon,
  isPointInsideExistingPolygon,
  isPolygonCoveringExistingPolygon,
  isPolygonSelfIntersecting,
  snapToClosestPolygonLine,
} from "@features/Cesium/utils";

import { base64ToBlob } from "@shared/helpers";

import { TEMP_POLYGON_ID, TOAST_IDS } from "../constants";

/**
 * Custom hook that handles drawing polygons on the Cesium viewer.
 *
 * @typedef {Object} DrawPolygonHookResult
 * @property {boolean} isDrawing - Indicates whether the user is currently drawing a polygon.
 * @property {function} startDrawing - Function to start drawing a new polygon.
 * @property {function} changeColorFill - Function to change the color fill of the polygon.
 * @property {function} onDiscard - Function to discard the drawn polygon.
 * @property {Array} coordinates - The centroid coordinates of the polygon.
 * @property {Array} vertices - The coordinates of the polygon points.
 * @property {string} screenshot - The Blob image of the drawn polygon.
 * @property {Object} polygonArea - An object containing the polygon area in different units.
 *
 * @param {Viewer} viewer - The Cesium Viewer instance.
 * @returns {DrawPolygonHookResult} An object containing functions and properties related to drawing polygons.
 */

const useDrawPolygon = ({
  viewer,
  setShowEntities,
  setDisableCesiumListeners,
}) => {
  const [isDrawing, setIsDrawing] = useState(false);
  const [polygonArea, setPolygonArea] = useState(0);
  const [screenshot, setScreenshot] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const vertices = useRef(null);
  const polygon = useRef(null);
  const polyPositions = useRef([]);
  const closingLine = useRef(null);
  const tempPoints = useRef([]);
  const hasLeftClickExecuted = useRef(false);
  const lastClickTimestamp = useRef(null);
  const snappedPositionRef = useRef(null);

  const discardPolygon = useCallback(() => {
    viewer.entities.remove(polygon.current);
    polygon.current = null;

    tempPoints.current.forEach((entity) => {
      viewer.entities.remove(entity);
    });
    tempPoints.current = [];
    polyPositions.current = [];
    lastClickTimestamp.current = null;

    setShowEntities({ polygons: true, assets: true });

    setPolygonArea(0);

    if (closingLine.current) {
      viewer.entities.remove(closingLine.current);
      closingLine.current = null;
    }

    vertices.current = null;
    setCoordinates(null);
    setScreenshot(null);

    setIsLoading(false);
    setIsDrawing(false);
    setDisableCesiumListeners(false);
    hasLeftClickExecuted.current = false;
    snappedPositionRef.current = null;
    viewer.scene.requestRenderMode = true;
  }, [setShowEntities, viewer, setDisableCesiumListeners]);

  useEffect(() => {
    if (!viewer) {
      return;
    }

    const handleLeftClick = (event) => {
      if (!isDrawing) {
        return;
      }

      const currentClickTimestamp = new Date().getTime();

      if (
        lastClickTimestamp.current !== null &&
        currentClickTimestamp - lastClickTimestamp.current < 250
      ) {
        lastClickTimestamp.current = currentClickTimestamp;
        return;
      }

      lastClickTimestamp.current = currentClickTimestamp;

      if (polyPositions.current.length >= 3 && polygonArea >= 100000) {
        toast.warning(
          "The polygon area should be smaller than 100,000m² or 1,076,391ft².",
          { toastId: TOAST_IDS.POLYGON_TOO_LARGE }
        );
        return;
      }

      const ray = viewer.camera.getPickRay(event.position);
      const position =
        snappedPositionRef.current ||
        viewer.scene.globe.pick(ray, viewer.scene);

      if (!cesiumDefined(position)) {
        return;
      }
      const cartographicPosition = cesiumCartographic.fromCartesian(position);
      const terrainHeight = viewer.scene.globe.getHeight(cartographicPosition);

      // Adjust the position with the terrain height
      cartographicPosition.height = terrainHeight;
      const accuratePosition =
        cesiumCartographic.toCartesian(cartographicPosition);

      // Temporarily add the new position to the list of polygon positions
      polyPositions.current.push(accuratePosition);

      // Check for intersections
      const polygonCoords = polyPositions.current.map((pos) => {
        const cartographic = cesiumCartographic.fromCartesian(pos);
        const longitude = cesiumMath.toDegrees(cartographic.longitude);
        const latitude = cesiumMath.toDegrees(cartographic.latitude);
        return [longitude, latitude];
      });

      // Remove the new position from the list of polygon positions
      polyPositions.current.pop();

      if (isPointInsideExistingPolygon(viewer, polygonCoords)) {
        toast.warning("You cannot add a point inside an existing polygon.", {
          toastId: TOAST_IDS.POINT_INSIDE_EXISTING,
        });
        return;
      }

      const lineString = turfLineString(polygonCoords);

      if (isLineIntersectingExistingPolygon(viewer, lineString)) {
        toast.warning("The line cannot intersect an existing polygon.", {
          toastId: TOAST_IDS.LINE_INTERSECT_EXISTING,
        });
        return;
      }

      if (isPolygonCoveringExistingPolygon(viewer, polygonCoords)) {
        toast.warning("The new polygon cannot cover an existing polygon.", {
          toastId: TOAST_IDS.POLYGON_COVERS_EXISTING,
        });
        return;
      }

      if (
        polyPositions.current.length >= 3 &&
        isPolygonSelfIntersecting(polygonCoords, lineString)
      ) {
        toast.warning("Self-intersection is not allowed.", {
          toastId: TOAST_IDS.SELF_INTERSECTION,
        });
        return;
      }

      tempPoints.current.push(
        viewer.entities.add({
          position: accuratePosition,
          point: {
            pixelSize: 3,
            color: cesiumColor.WHITE,
            heightReference: HeightReference.CLAMP_TO_GROUND,
          },
        })
      );

      snappedPositionRef.current = null;

      polyPositions.current.push(accuratePosition);

      if (polyPositions.current.length >= 2) {
        viewer.entities.add({
          polyline: {
            positions: new cesiumCallbackProperty(() => {
              return polyPositions.current;
            }, false),
            width: 4,
            material: cesiumColor.fromCssColorString("#F7BB3E"),
            clampToGround: true,
          },
        });
      }

      if (
        !cesiumDefined(polygon.current) &&
        polyPositions.current.length >= 3
      ) {
        polygon.current = new cesiumEntity({
          id: TEMP_POLYGON_ID,
          polygon: {
            hierarchy: new cesiumCallbackProperty(() => {
              return new cesiumPolygonHierarchy(polyPositions.current);
            }, false),
            material: cesiumColor.WHITE.withAlpha(0.5),
            outline: false,
            heightReference: HeightReference.CLAMP_TO_GROUND,
          },
        });
        viewer.entities.add(polygon.current);
      } else if (cesiumDefined(polygon.current)) {
        polygon.current.polygon.hierarchy = new cesiumCallbackProperty(() => {
          return new cesiumPolygonHierarchy(polyPositions.current);
        }, false);
      }
      hasLeftClickExecuted.current = true;
    };

    const handleMouseMove = (event) => {
      if (!isDrawing) {
        return;
      }

      const ray = viewer.camera.getPickRay(event.endPosition);
      let position = viewer.scene.globe.pick(ray, viewer.scene);
      if (!cesiumDefined(position)) {
        return;
      }

      const snappedPosition = snapToClosestPolygonLine(viewer, position);
      if (snappedPosition) {
        position = cesiumCartographic.toCartesian(snappedPosition);
        snappedPositionRef.current = position;
      } else {
        snappedPositionRef.current = null;
      }

      polyPositions.current.pop();
      polyPositions.current.push(position);

      if (polyPositions.current.length >= 3) {
        const polygonCoords = polyPositions.current.map((pos) => {
          const cartographic = cesiumCartographic.fromCartesian(pos);
          const longitude = cesiumMath.toDegrees(cartographic.longitude);
          const latitude = cesiumMath.toDegrees(cartographic.latitude);
          return [longitude, latitude];
        });

        // Close the polygon by adding the first coordinate to the end of the array
        polygonCoords.push(polygonCoords[0]);

        const polygonFeature = turfPolygon([polygonCoords]);
        const areaInSquareMeters = parseFloat(
          turfArea(polygonFeature).toFixed(2)
        );

        setPolygonArea(areaInSquareMeters);

        if (closingLine.current) {
          viewer.entities.remove(closingLine.current);
        }

        closingLine.current = viewer.entities.add({
          polyline: {
            positions: new cesiumCallbackProperty(() => {
              return [polyPositions.current[0], position];
            }, false),
            width: 4,
            material: cesiumColor.fromCssColorString("#F7BB3E"),
            clampToGround: true,
          },
        });
      }
    };

    const captureScreenshot = () => {
      const waitForRenderAndCapture = () => {
        // Wait for the scene to be rendered before capturing the screenshot
        const onPostRender = () => {
          viewer.scene.postRender.removeEventListener(onPostRender);

          const canvas = viewer.scene.canvas;
          const screenshotDataUrl = canvas.toDataURL("image/png", 0.3);

          const screenshotBlob = base64ToBlob(screenshotDataUrl);

          setScreenshot(screenshotBlob);
          setDisableCesiumListeners(true);
          setIsLoading(false);
        };

        viewer.scene.postRender.addEventListener(onPostRender);
        viewer.scene.requestRender();
      };

      requestAnimationFrame(waitForRenderAndCapture);
    };

    const handleLeftDoubleClick = (event) => {
      if (!isDrawing || !hasLeftClickExecuted.current) {
        return;
      }
      snappedPositionRef.current = null;

      const position = viewer.camera.pickEllipsoid(event.position);

      if (!cesiumDefined(position)) {
        return;
      }

      if (polygonArea >= 100000) {
        toast.warning(
          "The polygon area should be smaller than 100,000m² or 1,076,391ft².",
          { toastId: TOAST_IDS.POLYGON_TOO_LARGE }
        );
        return;
      }

      if (tempPoints.current.length < 3) {
        toast.warning("A polygon must have at least 3 points.", {
          toastId: TOAST_IDS.MIN_POINTS_REQUIRED,
        });
        return;
      }

      if (polygonArea < 1000) {
        toast.warning(
          "The polygon area should be at least 1,000 m² or 10,763.910 ft² large",
          { toastId: TOAST_IDS.POLYGON_TOO_SMALL }
        );
        return;
      }

      polyPositions.current.pop();

      // Check for intersections
      const polygonCoords = polyPositions.current.map((pos) => {
        const cartographic = cesiumCartographic.fromCartesian(pos);
        const longitude = cesiumMath.toDegrees(cartographic.longitude);
        const latitude = cesiumMath.toDegrees(cartographic.latitude);
        return [longitude, latitude];
      });

      // Ensure the polygon coordinates form a closed loop
      if (
        polygonCoords[0][0] !== polygonCoords[polygonCoords.length - 1][0] ||
        polygonCoords[0][1] !== polygonCoords[polygonCoords.length - 1][1]
      ) {
        polygonCoords.push(polygonCoords[0]);
      }

      if (isPointInsideExistingPolygon(viewer, polygonCoords)) {
        toast.warning("You cannot add a point inside an existing polygon.", {
          toastId: TOAST_IDS.POINT_INSIDE_EXISTING,
        });
        return;
      }

      const lineString = turfLineString(polygonCoords);

      if (isLineIntersectingExistingPolygon(viewer, lineString)) {
        toast.warning("The line cannot intersect an existing polygon.", {
          toastId: TOAST_IDS.LINE_INTERSECT_EXISTING,
        });
        return;
      }

      if (isPolygonCoveringExistingPolygon(viewer, polygonCoords)) {
        toast.warning("The new polygon cannot cover an existing polygon.", {
          toastId: TOAST_IDS.POLYGON_COVERS_EXISTING,
        });
        return;
      }

      if (isPolygonSelfIntersecting(polygonCoords, lineString)) {
        toast.warning("Self-intersection is not allowed.", {
          toastId: TOAST_IDS.SELF_INTERSECTION,
        });
        return;
      }

      setIsLoading(true);

      // Add last position to close the polygon
      polyPositions.current.push(polyPositions.current[0]);

      setCoordinates(
        getPolygonCoordinates(polyPositions.current).centroidCoordinates.map(
          (x) => parseFloat(x.toFixed(4))
        )
      );
      vertices.current = getPolygonCoordinates(
        polyPositions.current
      ).verticesCoordinates;

      if (closingLine.current) {
        viewer.entities.remove(closingLine.current);
        closingLine.current = null;
      }

      setShowEntities({ polygons: false, assets: false });

      flyToLand(viewer, vertices.current, captureScreenshot);

      setIsDrawing(false);
      setDisableCesiumListeners(false);
      viewer.scene.requestRenderMode = true;
    };

    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        if (isDrawing) {
          discardPolygon();
        }
      }
    };

    viewer.screenSpaceEventHandler.setInputAction(
      handleLeftClick,
      ScreenSpaceEventType.LEFT_CLICK
    );
    viewer.screenSpaceEventHandler.setInputAction(
      handleMouseMove,
      ScreenSpaceEventType.MOUSE_MOVE
    );
    viewer.screenSpaceEventHandler.setInputAction(
      handleLeftDoubleClick,
      ScreenSpaceEventType.LEFT_DOUBLE_CLICK
    );

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      viewer.screenSpaceEventHandler.removeInputAction(
        ScreenSpaceEventType.LEFT_CLICK
      );
      viewer.screenSpaceEventHandler.removeInputAction(
        ScreenSpaceEventType.MOUSE_MOVE
      );
      viewer.screenSpaceEventHandler.removeInputAction(
        ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    isDrawing,
    discardPolygon,
    setShowEntities,
    polygonArea,
    viewer,
    setDisableCesiumListeners,
  ]);

  const startDrawing = () => {
    const cameraAltitude = viewer.camera.positionCartographic.height;

    if (cameraAltitude > 10000) {
      toast.warning("Get the camera closer to start drawing.", {
        toastId: TOAST_IDS.GET_CLOSER,
      });
      return;
    }
    discardPolygon();
    setIsDrawing((prevState) => !prevState);
    setDisableCesiumListeners((prevState) => !prevState);
    setIsLoading(false);
    viewer.scene.requestRenderMode = false;
  };

  const onDiscard = () => {
    discardPolygon();
  };

  const changeColorAlpha = (color, alpha) => {
    if (polygon.current) {
      polygon.current.polygon.material = cesiumColor
        .fromCssColorString(color)
        .withAlpha(alpha !== undefined && alpha !== null ? alpha : 0.5);
    }
  };

  return {
    isDrawing,
    startDrawing,
    onDiscard,
    coordinates,
    polygonArea,
    screenshot,
    isLoading,
    changeColorAlpha,
    vertices: vertices.current,
  };
};

export { useDrawPolygon };
