import fetchData from "@api/axios";

const listingService = {
  async getFloorPriceForNfts(nftType) {
    return await fetchData({
      routeKey: "getFloorPriceForNfts",
      params: nftType,
    });
  },
  async getHighestOffer({ nftId }) {
    return await fetchData({
      routeKey: "getHighestOffer",
      params: nftId,
    });
  },

  async listNftForSale({ nftId, data }) {
    return await fetchData({
      routeKey: "listNftForSale",
      method: "POST",
      params: nftId,
      data,
    });
  },
  async declineNftOffer({ nftId, offererId }) {
    return await fetchData({
      routeKey: "declineNftOffer",
      method: "POST",
      params: nftId,
      data: { offererId },
    });
  },
  async cancelNftListing({ nftId }) {
    return await fetchData({
      routeKey: "cancelNftListing",
      method: "DELETE",
      params: nftId,
    });
  },
  async makeOfferForNft({ nftId, data }) {
    return await fetchData({
      routeKey: "makeOfferForNft",
      method: "POST",
      params: nftId,
      data,
    });
  },
};

export { listingService };
