import { useQuery } from "@tanstack/react-query";

const useQueryData = (queryKey, fetchFunction, config = {}) => {
  const queryResult = useQuery(queryKey, fetchFunction, {
    ...config,
  });
  return queryResult;
};

export { useQueryData };
