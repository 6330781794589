import clsx from "clsx";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";

import Button from "@components/Button";

import closeBtn from "@icons/close.svg";

import styles from "./Modal.module.css";

const Modal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  modalClassName,
  bodyClassName,
  footerClassName,
  children,
  cancelButton = null,
  confirmButton = null,
  headerDivider = false,
  footerDivider = false,
}) => {
  if (!isOpen) {
    return null;
  }

  const modalContent = (
    <div className={styles.overlay}>
      <div className={clsx(styles.modal, modalClassName)}>
        <div className={styles.header}>
          <div>
            <h2 className={styles.title}>{title}</h2>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
          {!onClose && (
            <button className={styles.closeButton} onClick={onClose}>
              <img src={closeBtn} alt="close button" width={16} height={16} />
            </button>
          )}
        </div>
        {headerDivider && <hr className={styles.headerDivider} />}
        <div className={clsx(styles.body, bodyClassName)}>{children}</div>
        {footerDivider && <hr className={styles.footerDivider} />}
        <div className={clsx(styles.footer, footerClassName)}>
          {cancelButton && (
            <Button
              onClick={cancelButton.onClick}
              className={clsx(styles.cancelButton, cancelButton.className)}
            >
              {cancelButton.text}
            </Button>
          )}
          {confirmButton && (
            <Button
              main
              onClick={confirmButton.onClick}
              className={clsx(styles.confirmButton, confirmButton.className)}
            >
              {confirmButton.text}
            </Button>
          )}
        </div>
      </div>
    </div>
  );

  return ReactDOM.createPortal(
    modalContent,
    document.getElementById("modal-root")
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  modalClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  cancelButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
  }),
  confirmButton: PropTypes.shape({
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    isLoading: PropTypes.bool,
  }),
  headerDivider: PropTypes.bool,
  footerDivider: PropTypes.bool,
};

export default Modal;
