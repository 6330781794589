// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QfBx0Vk0,
.BGmU0dN3,
.Y3ZFLmxB {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}

.pVHSdO5J {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.uhm2NjAx button {
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  display: flex;
  padding: 1rem;
}
.uhm2NjAx button:hover,
.uhm2NjAx button:active {
  background: rgba(108, 109, 114, 0.25);
}

.dKTfyImr {
  margin-right: 1rem;
  margin-top: 0.2rem;
}

.BlCk8SBG {
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  margin-left: 3rem;
  margin-bottom: 0.5rem;
}

.XCm6Ubr7 {
  font-weight: 600;
}

.QfBx0Vk0 {
  font-size: 16px;
  font-weight: 700;
  color: #f2f7f9;
  text-align: left;
}

.BGmU0dN3 {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  color: #989898;
  font-size: 12px;
  margin-top: 0.5rem;
  align-self: flex-end;
}

.Y3ZFLmxB {
  margin-top: 0.5rem;
  color: #2b3ff2;
  font-size: 0.75rem;
  padding: 1rem;
}

.KMcZcDbr {
  font-weight: 400;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationTitle": `QfBx0Vk0`,
	"notificationDate": `BGmU0dN3`,
	"clearMessages": `Y3ZFLmxB`,
	"notificationMessage": `pVHSdO5J`,
	"notificationMessages": `uhm2NjAx`,
	"notificationIcon": `dKTfyImr`,
	"notificationsHeading": `BlCk8SBG`,
	"unreadNotifications": `XCm6Ubr7`,
	"read": `KMcZcDbr`
};
export default ___CSS_LOADER_EXPORT___;
