import { useQueryData } from "@api/hooks";
import { assetService } from "@api/services";

const useAssetsQuery = (config) => {
  const { data, ...restOfQueryParams } = useQueryData(
    ["assets"],
    async () => await assetService.getAssets(),
    { ...config }
  );

  return {
    assets: data,
    ...restOfQueryParams,
  };
};

export { useAssetsQuery };
