import PropTypes from "prop-types";
import { useState } from "react";

import { useWalletState } from "@Web3/context";

import { stringToGradient } from "@shared/helpers";

import styles from "./UploadImage.module.css";

const UploadImage = ({ id, name, accept, register, onFileChange, data }) => {
  const [preview, setPreview] = useState(data);

  const { currentAddress } = useWalletState();

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setPreview(data);
    }
    onFileChange(event);
  };

  return (
    <div className={styles.uploadImageContainer}>
      <input
        {...register}
        type="file"
        accept={accept}
        name={name}
        id={id}
        className={styles.uploadImageInput}
        onChange={handleFileChange}
      />
      <label htmlFor={id} className={styles.uploadImageLabel}>
        {preview ? (
          <img
            src={preview}
            alt="Profile Preview"
            className={styles.uploadImagePreview}
          />
        ) : (
          <div
            className={styles.uploadImagePreview}
            style={{
              backgroundImage: currentAddress
                ? stringToGradient(currentAddress)
                : null,
            }}
          />
        )}
      </label>
    </div>
  );
};

UploadImage.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  accept: PropTypes.string,
  register: PropTypes.object,
  onFileChange: PropTypes.func.isRequired,
  data: PropTypes.string,
};

export default UploadImage;
