const getMessageToSign = (currentAddress) => {
  return `Welcome to Decentralverse AI!\n\nClick to sign in!\n\nWallet address:\n${currentAddress}\n`;
};

const formatNumber = (num) => {
  if (num < 0.00000000001) {
    return "0";
  }
  let formattedNum = num.toFixed(8);
  formattedNum = parseFloat(formattedNum);
  let stringNum = formattedNum.toString();

  const dotIndex = stringNum.indexOf(".");
  if (dotIndex === -1) {
    stringNum += ".00";
  } else {
    const decimalPlaces = stringNum.length - dotIndex - 1;
    if (decimalPlaces < 2) {
      stringNum += "0".repeat(2 - decimalPlaces);
    }
  }
  return stringNum;
};

module.exports = {
  formatNumber,
  getMessageToSign,
};
