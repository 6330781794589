import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

const useMutationData = (mutationFunction, config = {}) => {
  const queryClient = useQueryClient();

  const { invalidateQueries: queriesToInvalidate, ...otherConfig } = config;

  const mutationResult = useMutation(mutationFunction, {
    onError: (error) => {
      toast.error(`Error: ${error.message}`);
    },
    onSuccess: (data) => {
      if (queriesToInvalidate && Array.isArray(queriesToInvalidate)) {
        queriesToInvalidate.forEach((queryKey) => {
          queryClient.invalidateQueries(queryKey);
        });
      }
      if (config.onSuccess) {
        config.onSuccess(data);
      }
    },
    ...otherConfig,
  });

  return mutationResult;
};

export { useMutationData };
