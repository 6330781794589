export const METAMASK = "metamask";
export const WALLET_CONNECT = "wallet-connect";
export const CACHED_CONNECTOR_ID_KEY = "CACHED_CONNECTOR_ID";

export const providerEvents = {
  CHAIN_CHANGED: "chainChanged",
  ACCOUNTS_CHANGED: "accountsChanged",
  DISCONNECT: "disconnect",
};

export const WALLET_ACTION_ERROR_CODES = {
  "-32002": {
    message: "Metamask is locked, please open the extension before connecting",
  },
  "-32603": {
    message: "Internal JSON RPC error.",
  },
  4001: {
    message: "User has rejected the request",
  },
  4100: {
    message: "Action is not authorized by wallet",
  },
  32003: {
    message: "Insufficient funds in your wallet",
  },
  500: {
    message: "User has rejected the request",
  },
  5000: {
    message: "You rejected the transaction in your wallet",
  },
};

export const CONTRACT_ERROR_CODES = {
  ACTION_REJECTED: "User has rejected the request",
  TRANSACTION_REPLACED: {
    cancelled: "Your transaction has been cancelled",
    replaced: "Your transaction has been replaced",
    repriced: "Your transaction has been repriced",
  },
};

export const Erc20ContractMethods = {
  APPROVE: "approve",
  ALLOWANCE: "allowance",
  BALANCE_OF: "balanceOf",
};

export const Web3QueryKeys = {
  NATIVE_BALANCE: "native-balance",
  ERC20_BALANCE: "erc20-balance",
};

export const Web3WalletEvent = {
  WALLET_RECONNECTED: "wallet-reconnected",
  WALLET_TRANSACTION_SUCCESS: "wallet-transaction-success",
  WALLET_TRANSACTION_ERROR: "wallet-transaction-error",
  CONNECT_SUCCESS: "connect-success",
  CONNECT_ERROR: "connect-error",
  DISCONNECT_SUCCESS: "disconnect-success",
  DISCONNECT_ERROR: "disconnect-error",
  WALLET_MODAL_OPEN: "wallet-modal-open",
  WALLET_MODAL_CLOSE: "wallet-modal-close",
};
