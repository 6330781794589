import fetchData from "@api/axios";

const whitelistService = {
  async checkWhitelistEmail({ data }) {
    return await fetchData({
      routeKey: "checkWhitelistEmail",
      method: "POST",
      data,
    });
  },
};

export { whitelistService };
