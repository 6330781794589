const config = {
  app: {
    cesiumApiKey: process.env.REACT_APP_CESIUM_API,
  },
  contracts: {
    landCollectionAddress:
      process.env.REACT_APP_LAND_COLLECTION_CONTRACT_ADDRESS,
    assetCollectionAddress:
      process.env.REACT_APP_ASSET_COLLECTION_CONTRACT_ADDRESS,
    dcvExchangeAddress:
      process.env.REACT_APP_DECENTRALVERSE_EXCHANGE_CONTRACT_ADDRESS,
    royaltiesProviderAddress:
      process.env.REACT_APP_DECENTRALVERSE_ROYALTIES_CONTRACT_ADDRESS,
    erc20Address: process.env.REACT_APP_ERC20_CONTRACT_ADDRESS,
  },
  blockchain: {
    chainId: process.env.REACT_APP_CHAIN_ID,
    chainName: process.env.REACT_APP_CHAIN_NAME,
    nativeTokenName: process.env.REACT_APP_NATIVE_TOKEN_NAME,
    tokenName: process.env.REACT_APP_TOKEN_NAME,
    nativeTokenSymbol: process.env.REACT_APP_NATIVE_TOKEN_SYMBOL,
    tokenSymbol: process.env.REACT_APP_TOKEN_SYMBOL,
    tokenDecimals: process.env.REACT_APP_TOKEN_DECIMALS,
    rpcUrl: process.env.REACT_APP_RPC_URL,
    blockExplorerUrl: process.env.REACT_APP_BLOCK_EXPLORER_URL,
    walletConnectProjectId: process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
    infuraApiKey: process.env.REACT_APP_INFURA_API_KEY,
  },
};

export default config;
