import {
  lineString as turfLineString,
  nearestPointOnLine as turfNearestPointOnLine,
  point as turfPoint,
} from "@turf/turf";
import { Cartographic as cesiumCartographic, Math as cesiumMath } from "cesium";

import { TEMP_POLYGON_ID } from "../constants";

function getThreshold(cameraHeight) {
  let threshold = 10; // minimum threshold
  if (cameraHeight > 1000) {
    threshold = cameraHeight / 100;
  }
  return threshold;
}

export const snapToClosestPolygonLine = (viewer, position) => {
  const cartographicPosition = cesiumCartographic.fromCartesian(position);
  const longitude = cesiumMath.toDegrees(cartographicPosition.longitude);
  const latitude = cesiumMath.toDegrees(cartographicPosition.latitude);
  const cameraHeight = viewer.camera.positionCartographic.height;
  const thresholdInMeters = getThreshold(cameraHeight);
  const point = turfPoint([longitude, latitude]);

  let closestSnappedPoint = null;
  let smallestDist = Number.MAX_VALUE;

  const entities = viewer.entities.values;
  for (const entity of entities) {
    if (entity.polygon && entity.id !== TEMP_POLYGON_ID) {
      const hierarchy = entity.polygon.hierarchy.getValue(
        viewer.clock.currentTime
      );
      const positions = hierarchy.positions;

      for (let i = 0; i < positions.length; i++) {
        const start = positions[i];
        const end = positions[i + 1] || positions[0];

        const cartographicStart = cesiumCartographic.fromCartesian(start);
        const cartographicEnd = cesiumCartographic.fromCartesian(end);

        const startLongitude = cesiumMath.toDegrees(
          cartographicStart.longitude
        );
        const startLatitude = cesiumMath.toDegrees(cartographicStart.latitude);
        const endLongitude = cesiumMath.toDegrees(cartographicEnd.longitude);
        const endLatitude = cesiumMath.toDegrees(cartographicEnd.latitude);

        const lineString = turfLineString([
          [startLongitude, startLatitude],
          [endLongitude, endLatitude],
        ]);

        const snapped = turfNearestPointOnLine(lineString, point, {
          units: "meters",
        });

        if (
          snapped.properties.dist < smallestDist &&
          snapped.properties.dist <= thresholdInMeters
        ) {
          smallestDist = snapped.properties.dist;
          closestSnappedPoint = cesiumCartographic.fromDegrees(
            snapped.geometry.coordinates[0],
            snapped.geometry.coordinates[1],
            cartographicPosition.height
          );
        }
      }
    }
  }
  return closestSnappedPoint;
};
