// Convert a string (like Ethereum address) to a color
export const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const c = (hash & 0x00ffffff).toString(16).toUpperCase();
  return "#" + "00000".substring(0, 6 - c.length) + c;
};

// Create a gradient based on a single Ethereum address
export const stringToGradient = (str) => {
  const color1 = stringToColor(str);
  const color2 = stringToColor(str.slice(0, -4));
  return `linear-gradient(45deg, ${color1}, ${color2})`;
};
