import { useCallback, useState } from "react";

import {
  fileTypes,
  formatFileSize,
  getFileType,
  truncateFileName,
} from "../helpers/file";

export default function useFileUpload({
  acceptedFiles,
  maxSize,
  onFileSelected,
}) {
  const [preview, setPreview] = useState(null);
  const [fileDetails, setFileDetails] = useState({
    name: "",
    type: "",
    size: "",
  });
  const [fileError, setFileError] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [dragging, setDragging] = useState(false);

  const getPercentage = useCallback((event) => {
    const percentage = (event.loaded / event.total) * 100;
    setUploadProgress(percentage);
  }, []);

  const onFileChange = useCallback(
    (e) => {
      const file = e.target.files[0];

      if (!file) {
        return;
      }

      if (uploadProgress) {
        setUploadProgress(0);
      }

      if (fileError) {
        setFileError(null);
      }

      if (file.size > maxSize) {
        setFileError(
          `File size exceeds limit of ${(maxSize / 1024 ** 2).toFixed(2)} MB`
        );
        setPreview(null);
        return true;
      }

      let fileType = file.type;
      if (!fileType) {
        fileType = getFileType(file.name);
      }
      if (!acceptedFiles.includes(fileType)) {
        setFileError(`Accepts only ${acceptedFiles.join(", ")}`);
        setPreview(null);
        return true;
      }

      setFileDetails({
        name: truncateFileName(file.name, 25, fileType),
        type: fileType,
        size: formatFileSize(file.size),
      });
      const reader = new FileReader();

      reader.addEventListener("progress", getPercentage);

      reader.onloadend = () => {
        if (fileType === fileTypes.glb) {
          const blob = new Blob([reader.result], { type: fileType });
          const url = URL.createObjectURL(blob);
          setPreview(url);
        } else {
          setPreview(reader.result);
        }

        reader.removeEventListener("progress", getPercentage);
      };
      if (fileType === fileTypes.glb) {
        reader.readAsArrayBuffer(file);
      } else {
        reader.readAsDataURL(file); // use readAsDataURL for images
      }

      onFileSelected(file);
    },
    [
      uploadProgress,
      fileError,
      maxSize,
      acceptedFiles,
      getPercentage,
      onFileSelected,
    ]
  );

  const onDragOver = useCallback((e) => {
    e.preventDefault();
    setDragging(true);
  }, []);

  const onDragLeave = useCallback((e) => {
    e.preventDefault();
    setDragging(false);
  }, []);

  const onDrop = useCallback(
    (e) => {
      e.preventDefault();
      setDragging(false);
      const files = e.dataTransfer.files;
      if (files.length) {
        const event = { target: { files } };
        onFileChange(event);
      }
    },
    [onFileChange]
  );

  return {
    preview,
    fileDetails,
    fileError,
    uploadProgress,
    dragging,
    setPreview,
    onFileChange,
    onDragOver,
    onDragLeave,
    onDrop,
  };
}
