// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.U4_kujAq {
  width: 100%;
  position: absolute;
  bottom: 124px;
  display: flex;
  flex-direction: column;
}
.U4_kujAq > * {
  padding: 0 1.5rem;
  margin-bottom: 1rem;
}

.U4_kujAq button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1rem;
  line-height: 22px;
  text-align: left;
  color: #ff0691;
  padding: 0.5rem 0.5rem 0.5rem 1.5rem;
  margin-bottom: 0;
  cursor: pointer;
  flex: 0 0 auto;
}

.U4_kujAq button:hover {
  background: rgba(108, 109, 114, 0.25);
}

.U4_kujAq p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;

  color: #f2f7f9;
}

.yfTlVM2o {
  position: absolute;
  color: #14b7ff;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  text-decoration: none;
  bottom: -3rem;
}

.DB4T3ZFU,
.yfTlVM2o {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 85%;
  display: inline-block;
  vertical-align: middle;
}

.yfTlVM2o {
  max-width: 95%;
}

.U4_kujAq p,
.yfTlVM2o {
  flex: 1;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"userContent": `U4_kujAq`,
	"userEmail": `yfTlVM2o`,
	"username": `DB4T3ZFU`
};
export default ___CSS_LOADER_EXPORT___;
