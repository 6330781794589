// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.VsmA_QVT {
  display: flex;
  flex-direction: column;
}

.VsmA_QVT > a {
  padding: 1rem;
  text-decoration: none;
  cursor: pointer;
}

.VsmA_QVT > a:last-of-type {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #cbd5e0;
}

.VsmA_QVT > a:hover,
.EfYVZH2S:hover {
  background: rgba(108, 109, 114, 0.25);
}

.EfYVZH2S {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
  cursor: pointer;
}

.EfYVZH2S > a {
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpContent": `VsmA_QVT`,
	"socials": `EfYVZH2S`
};
export default ___CSS_LOADER_EXPORT___;
