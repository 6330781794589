import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { whitelistService } from "@api/services";

import Countdown from "@features/Countdown";

import { Input } from "@components/Form";
import { Loader } from "@components/Loader";

import storage from "@services/storage";

import { clsx } from "@shared/helpers";

import decentralverseLogo from "@icons/logo.svg";
import bgDecentralverseVideo from "@videos/whitelist-bg-video.mp4";

import styles from "./Whitelist.module.css";

const targetDate = "2024-09-25T00:00:00+01:00";
const timeZone = "Europe/Zagreb";

const Whitelist = ({ onValidAddress }) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async (data) => {
    const { email } = data;
    try {
      const isEmailAllowedToEnter = await whitelistService.checkWhitelistEmail({
        data: { email },
      });
      if (isEmailAllowedToEnter) {
        onValidAddress();
        storage.save("whitelist", email);
        return;
      }
      setError("email", {
        type: "manual",
        message: "You are not whitelisted, contact us",
      });
    } catch (error) {
      toast.error("Something went wrong, please contact us.");
    }
  };

  return (
    <div className={styles.fullScreen}>
      <video autoPlay muted loop className={styles.video}>
        <source src={bgDecentralverseVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.logoContainer}>
            <img
              className={styles.logo}
              src={decentralverseLogo}
              alt="Decentralverse logo"
            />
            <h1 className={styles.title}>Decentralverse AI</h1>
          </div>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            className={styles.form}
          >
            <div className={styles.inputWrapper}>
              <Input
                id="email"
                name="email"
                type="email"
                className={styles.input}
                placeholder="Your email"
                register={register("email", {
                  required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
                error={
                  errors?.email?.message?.includes("whitelisted")
                    ? ""
                    : errors?.email?.message
                }
              />
              {errors?.email?.message?.includes("whitelisted") && (
                <span className={styles.whitelistError}>
                  You are not whitelisted,{" "}
                  <a
                    href="https://docs.decentralverse.io/references/contact-us"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    contact us
                  </a>
                </span>
              )}
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className={clsx(styles.button, styles.someClass)}
            >
              {isSubmitting ? <Loader size="xs" /> : "Enter the app"}
            </button>
          </form>
          <p className={styles.inviteOnly}>Invite Only Sign Ups</p>
        </div>
      </div>
      <Countdown
        targetDate={targetDate}
        timeZone={timeZone}
        className={styles.countdown}
      />
      <p className={styles.copyright}>© 2024 Decentralverse</p>
    </div>
  );
};

Whitelist.propTypes = {
  onValidAddress: PropTypes.func,
};

export default Whitelist;
