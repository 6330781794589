import PropTypes from "prop-types";

import { clsx } from "@shared/helpers";

import closeBtn from "@icons/close.svg";

import styles from "./Modal.module.css";

const Modal = ({ title, onClose, children, size, className }) => {
  return (
    <div
      className={clsx(
        styles.modalOverlay,
        size === "large" ? styles.large : styles.small,
        className
      )}
    >
      <div className={styles.modal}>
        <div className={styles.modalHeading}>
          <h3 className={styles.title}>{title}</h3>
          {onClose && typeof onClose === "function" && (
            <button className={styles.modalCloseButton} onClick={onClose}>
              <img
                className={styles.modalCloseImg}
                src={closeBtn}
                alt="close button"
              />
            </button>
          )}
        </div>
        <div className={styles.modalContent}>{children}</div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  children: PropTypes.node,
  size: PropTypes.oneOf(["small", "large"]),
  className: PropTypes.string,
};

export default Modal;
