export const fileTypes = {
  glb: ".glb",
  glft: ".gltf",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
};

export const getFileType = (fileName) => {
  const extension = fileName.split(".").pop().toLowerCase();
  return fileTypes[extension];
};

export const formatFileSize = (size) => {
  const sizeKB = size / 1024;
  if (sizeKB < 1024) {
    return `${sizeKB.toFixed(2)} KB`;
  } else {
    return `${(sizeKB / 1024).toFixed(2)} MB`;
  }
};

export const truncateFileName = (name, maxLength = 25, fileType) => {
  if (name.length > maxLength) {
    return `${name.substring(0, maxLength)}...(${fileType})`;
  }

  return name;
};
