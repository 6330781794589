import {
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  defined as cesiumDefined,
} from "cesium";
import { useCallback, useEffect, useMemo, useState } from "react";

import { useQueryData } from "@api/hooks";
import { assetService } from "@api/services";

const useAssetQuery = ({ viewer, loadHandler, enabled }) => {
  const [assetId, setAssetId] = useState(null);

  const { data } = useQueryData(
    ["assets", assetId],
    async () => {
      return await assetService.getAsset(assetId);
    },
    { enabled: !!assetId }
  );

  const onAssetPick = useCallback((id) => {
    setAssetId(id);
  }, []);

  useEffect(() => {
    if (!viewer?.canvas || !viewer?.scene || !enabled) {
      return;
    }

    const handler = new ScreenSpaceEventHandler(viewer.canvas);

    handler.setInputAction((event) => {
      const pickedObject = viewer.scene.pick(event.position);

      if (
        cesiumDefined(pickedObject) &&
        cesiumDefined(pickedObject.primitive?.id)
      ) {
        const entityId = pickedObject.primitive.id;
        const assetOwnerId = pickedObject.primitive.ownerId;
        const [type, ...rest] = entityId.split("-");
        const id = rest.join("-");
        if (type === "asset") {
          setAssetId(id);
          loadHandler(assetOwnerId);
        }
      }
    }, ScreenSpaceEventType.LEFT_CLICK);

    return () => {
      handler.destroy();
    };
  }, [viewer?.canvas, enabled, viewer?.scene, loadHandler]);

  return useMemo(
    () => ({ asset: data, assetId, onAssetPick }),
    [assetId, data, onAssetPick]
  );
};

export { useAssetQuery };
